<div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
  <h1 class="text-white">{{ 'home.page.title' | translate }} {{ name$ | async }}</h1>
</div>

<!-- Default Population || Partner Population -->
<div *notInPopulation="[populations.NonRenewingPopulation]">
  <ng-container *ifScreenSize="screenType.Mobile">
    <div class="container my-3">
      <fgb-barcode></fgb-barcode>
    </div>
  </ng-container>

  <ng-container *notInPopulation="[populations.Suite, populations.Pts]">
    <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
  </ng-container>

  <div class="container">
    <fgb-vouchers *inPopulation="[populations.Default]"></fgb-vouchers>
  </div>

  <div>
    <!-- Featured Rewards -->
    <div class="container" [ngClass]="{ free: currentPopulation == 'Free' || currentPopulation == 'Partners' }">
      <fgbcl-featured-rewards
        *inPopulation="[populations.Default, populations.Free, populations.Partner]"
        [showAllPath]="'rewards'"
      ></fgbcl-featured-rewards>
    </div>

    <!-- Campaign Offers -->
    <ng-container *inPopulation="[populations.Default, populations.Free, populations.Partner]">
      <div class="offers-container py-2 mb-3 mt-3">
        <fgbcl-campaigns-offers-list
          (dataChange)="onCampaignOfferListChange($event)"
          [showAllOffers]="true"
        ></fgbcl-campaigns-offers-list>
      </div>
    </ng-container>

    <!-- Announcements -->
    <div class="container" [ngClass]="{ free: currentPopulation == 'Free' || currentPopulation == 'Partners' }">
      <fgbcl-announcements
        *inPopulation="[populations.Default, populations.Partner, populations.Free, populations.Suite, populations.Pts]"
      ></fgbcl-announcements>
    </div>
  </div>
</div>
<!-- Nonrenewing Population -->
<div *inPopulation="[populations.NonRenewingPopulation]">
  <ng-container *ifScreenSize="screenType.Mobile">
    <div class="card text-center mt-3 ms-3 me-3">
      <div class="row mt-2 ps-3 pe-3">
        <div class="col-lg-6 ps-0 pe-0">
          <div *ngIf="memberDetails$ | async as memberDetails">
            <div class="mb-0 card-name text-primary text-uppercase">
              {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
            </div>
            <ng-container *ngIf="memberCard$ | async as memberCard">
              <div class="text-primary font-size-12 mb-2">{{ memberCard.ExternalRefNumber }}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center mt-3">
      <div class="row mt-3 ps-3 pe-3">
        <div class="col-lg-6 ps-0 pe-0">
          <img src="assets/images/login-registration/minnesotacover.png" class="w-100 h-100 m-auto border-style-dkt" />
        </div>
        <div class="border-style-dkb col-lg-6 ps-0 border">
          <p class="renewing text-secondary text-start font-size-18 justify-content-start mt-3 ps-3 mb-2">
            {{ 'home.page.renewing.heading' | translate }}
          </p>
          <p
            class="renewing text-primary font-size-12 text-bold text-start mt-3 justify-content-start ps-3"
            [innerHTML]="'home.page.renewing.label' | translate | markdownTranslate"
          ></p>
        </div>
      </div>
      <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
        <div class="col-12 mt-3">
          <h5 class="text-start mb-1 mt-lg-5">{{ 'partner.accept' | translate }}</h5>
          <div class="mb-2 mt-2 mt-lg-0">
            <div class="row m-0 partner-card">
              <p class="m-0 text-start font-size-14">
                {{ 'partner.text.message' | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 mb-3">
          <button
            class="accept-partner-button border-0 text-center text-white bg-green pt-0 d-flex align-items-center justify-content-center"
            (click)="openAcceptForm()"
            [ngClass]="{ active: !showAddForm, 'not-active': showAddForm, 'greyed-out': currentPartnerCount >= partnerLimit }"
            aria-label="Accept partner"
          >
            <span class="material-icons me-2"> task_alt </span>
            <label class="cursor-pointer mb-0"> {{ 'nav.accept.partner' | translate: { fgbdefault: 'Accept Partner' } }}</label>
          </button>

          <hr class="my-1 bg-white w-100 mt-3 mb-4" />
        </div>
      </ng-container>
      <div class="col-12 mt-3">
        <button
          class="renewing-partner-button border-0 text-center text-white bg-secondary"
          (click)="toggleAcceptForm()"
          [ngClass]="{ active: !showAddForm, 'not-active': showAddForm, 'greyed-out': currentPartnerCount >= partnerLimit }"
          aria-label="Log Out"
        >
          <span aria-hidden="true" class="icon material-icons material-mobile-dropdown">power_settings_new</span>
          <label class="cursor-pointer mb-0 ms-2 font-size-14">{{ 'nav.log.out' | translate: { fgbdefault: 'Log out' } }}</label>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ifScreenSize="screenType.Desktop">
    <div class="container text-center mt-5">
      <div class="row mt-3 ps-3 pe-3">
        <div class="col-lg-6 ps-0 pe-0">
          <img src="assets/images/login-registration/minnesotacover.png" class="w-100 h-100 m-auto border-style-dkl" />
        </div>
        <div class="border-style-dkr col-lg-6 ps-0 border">
          <p class="renewing text-secondary text-start font-size-18 justify-content-start mt-3 ps-3 mb-2">
            {{ 'home.page.renewing.heading' | translate }}
          </p>
          <p
            class="renewing text-primary font-size-12 text-bold text-start mt-3 justify-content-start ps-3"
            [innerHTML]="'home.page.renewing.label' | translate | markdownTranslate"
          ></p>
        </div>
      </div>
    </div>
    <!-- Accept partner-->
    <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
      <div class="container">
        <div class="row m-0 partner-card">
          <div class="col-12 mt-1">
            <h5 class="text-start mb-1 mt-lg-5">{{ 'partner.accept' | translate }}</h5>
            <div class="mb-2 mt-2 mt-lg-0">
              <div class="row m-0 partner-card">
                <p class="m-0 text-info font-size-14">
                  {{ 'partner.text.message' | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <button
              class="dkt-accept-partner-button border-0 text-center text-white bg-green pt-0 d-flex align-items-center justify-content-center"
              (click)="openAcceptForm()"
              [ngClass]="{ active: !showAddForm, 'not-active': showAddForm, 'greyed-out': currentPartnerCount >= partnerLimit }"
              aria-label="Accept partner"
            >
              <span class="material-icons me-2"> task_alt </span>
              <label class="text-bold font-size-14 cursor-pointer mb-0">{{ 'accept.partner' | translate }}</label>
            </button>
          </div>
          <hr class="my-1 bg-white w-100 mb-5" />
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
