import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalDismissReasons, NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService, ChildModel, ChildRequestModel, ChildService } from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'fgb-kids-linked-page',
  templateUrl: './kids-linked-page.component.html',
  styleUrls: ['./kids-linked-page.component.scss'],
})
export class KidsLinkedPageComponent implements OnInit {
  @Output() submittedRequest = new EventEmitter<boolean>();

  totalAccount: number = 10;
  addChildForm: FormGroup;
  childRequest: ChildRequestModel;
  selectedChild: ChildModel;
  childList$: Observable<ChildModel[]>;
  linkCode: string;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private childService: ChildService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.addChildForm = this.formBuilder.group({
      MemberId: [this.childRequest?.MemberId],
      LastName: [this.childRequest?.LastName],
      DateOfBirth: [this.childRequest?.DateOfBirth],
    });
    this.childList$ = this.childService.getLinkRequests();
  }

  openAddChildForm(childInfo: any, disabled: boolean) {
    if (disabled) return;
    this.modalService.open(childInfo, { windowClass: 'add-child-modal modal-dialog-centered', centered: true }).result.then(
      () => {},
      (reason) => {
        if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
        }
      }
    );
  }

  closeDropDown(dropdown: NgbDropdown) {
    dropdown.close();
  }

  openEnterCode(enterCode: any, selectedChild: ChildModel) {
    this.selectedChild = selectedChild;
    this.modalService.open(enterCode, { centered: true }).result.then(
      () => {},
      (reason) => {
        if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
        }
      }
    );
  }

  sendRequest() {
    this.childRequest = {
      ...this.childRequest,
      ...this.addChildForm.value,
      DateOfBirth: this._getStringFromNgbDate(this.addChildForm.get('DateOfBirth')!.value),
    };

    if (this.addChildForm.invalid) {
      return;
    }

    this.childService
      .linkRequest(this.childRequest.MemberId, this.childRequest.LastName, this.childRequest.DateOfBirth)
      .then(() => {
        window.location.reload();
        this.submittedRequest.emit(true);
      });
  }

  resendRequest() {
    this.childService
      .linkRequest(this.selectedChild.MemberId, this.selectedChild.Lastname, this.selectedChild.DateOfBirth)
      .then(() => {
        this.submittedRequest.emit(true);
      });
  }

  confirmLink() {
    this.childService.processLinkRequest(this.linkCode).then(() => {
      window.location.reload();
      this.submittedRequest.emit(true);
    });
  }

  unlink(child: ChildModel) {
    this.childService.deleteLinkRequest(child.MemberId).then(() => {
      window.location.reload();
      this.submittedRequest.emit(true);
    });
  }

  loginToChild(child: ChildModel) {
    firstValueFrom(this.authService.switchUser(child.MemberId));
  }

  /** Gets a date string in ISO format from an NgbDate. */
  private _getStringFromNgbDate(ngbDate: NgbDate) {
    if (ngbDate) {
      let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day, 12);
      date.setUTCHours(0);
      date.setUTCMinutes(0);
      date.setUTCSeconds(0);
      return date.toISOString();
    } else {
      return '';
    }
  }
}
