<form [formGroup]="addForm" class="row mt-3" (ngSubmit)="submit()">
  <div class="row w-100 border-bottom">
    <div class="partner-form-title w-100 font-sans-bold pb-3">
      {{ 'partner.add.form.title' | translate }}
    </div>
  </div>
  <div class="px-2 row w-100">
    <div class="col-lg-6 col-12">
      <div class="form-floating mb-1">
        <input
          formControlName="fullName"
          type="text"
          class="custom-field-form form-control field"
          id="customField"
        />
        <label class="display-name-custom-field" for="floatingInputCustomField">{{'partner.add.fullname' | translate}}</label>
      </div>
    </div>
    <div class="col-lg-6 col-12 mt-lg-0 mt-3">
      <div class="form-floating mb-1">
        <input
          formControlName="email"
          type="text"
          class="custom-field-form form-control field"
          id="customField"
        />
        <label class="display-name-custom-field" for="floatingInputCustomField">{{'partner.add.form.email.label' | translate}}</label>
      </div>
    </div>
    <div class="mt-2 px-2 error-message">
      <fgbcl-errors></fgbcl-errors>
    </div>
  </div>

  <div class="button-container d-flex justify-content-end form-buttons mb-3 w-100">
    <button (click)="close(); $event.preventDefault()" class="btn btn-danger fw-bold">
      {{ 'partner.add.form.cancel.button' | translate }}
    </button>
    <button type="submit" [disabled]="addForm.invalid" class="btn btn-success fw-bold">
      {{ 'partner.add.form.submit.button' | translate }}
    </button>
  </div>
</form>
