import { Component, OnInit } from '@angular/core';
import { AuctionBidDetails, AuctionBidQuery, AuctionBidService } from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'fgb-auction-bid-list',
  templateUrl: './auction-bid-list.component.html',
  styleUrls: ['./auction-bid-list.component.scss'],
})
export class AuctionBidListComponent implements OnInit {
  constructor(
    private auctionBidsQuery: AuctionBidQuery,
    private bidService: AuctionBidService
  ) {}
  auctionBids$: Observable<AuctionBidDetails[]>;

  ngOnInit() {
    firstValueFrom(this.bidService.fetchBids());
    this.auctionBids$ = this.auctionBidsQuery.selectWonBids();
  }
}
