<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none" *notInPopulation="[populations.NonRenewingPopulation]">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex bg-primary justify-content-between">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/MyTwins_PrimaryLogo_Light.png" draggable="false" />
    </a>
    <ng-container *inPopulation="[populations.Suite, populations.Pts]">
      <a class="nav-item" [routerLink]="['/account']">
        <span
          class="icon material-icons material-mobile-nav text-white"
          role="img"
          attr.aria-label="{{ 'nav.account' | translate }}"
          >person</span
        >
      </a>
      <a *notInPopulation="[populations.Pts]" class="nav-item" [routerLink]="['/partners']">
        <span
          class="icon material-icons material-mobile-nav text-white"
          role="img"
          attr.aria-label="{{ 'nav.partner' | translate }}"
          >group</span
        >
      </a>
    </ng-container>
    <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
      <a
        class="nav-item"
        [routerLink]="['/account']"
        *inPopulation="[populations.Default, populations.Partner, populations.Free]"
      >
        <span
          class="icon material-icons material-mobile-nav text-white"
          role="img"
          attr.aria-label="{{ 'nav.account' | translate }}"
          >person</span
        >
      </a>
      <a
        class="nav-item"
        [routerLink]="['/rewards/wallet']"
        *inPopulation="[populations.Default, populations.Partner, populations.Free]"
      >
        <span
          class="icon material-icons material-mobile-nav text-white"
          role="img"
          attr.aria-label="{{ 'nav.wallet' | translate }}"
          >account_balance_wallet</span
        >
      </a>
    </ng-container>
    <div class="nav-item" *inPopulation="[populations.Default, populations.Partner, populations.Free]">
      <fgb-notification-button></fgb-notification-button>
    </div>

    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item fw-bold navbar-toggler m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>
  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown bg-primary">
    <div class="d-flex flex-column px-2 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon material-icons">close</span>
      </button>

      <!-- Accounts Menu Section -->
      <div class="d-flex align-items-center justify-content-start mt-4 mb-2">
        <img src="assets/images/MyTwins_PrimaryLogo_Light.png" class="brand-logo" />
      </div>
      <div *ngIf="memberDetails$ | async as memberDetails" class="mb-3">
        <div class="d-flex justify-content-between">
          <div class="mb-0 text-uppercase text-white member-name">{{ memberDetails.FirstName }} {{ memberDetails.Surname }}</div>
          <img
            *ngIf="tierName == tierLevels.Mvp"
            alt=""
            src="assets/images/tier-logo/tier_logo_mvp.svg"
            draggable="false"
            height="18"
          />
          <img
            *ngIf="tierName == tierLevels.AllStar"
            alt=""
            src="assets/images/tier-logo/tier_logo_all_star.svg"
            draggable="false"
            height="18"
          />
          <img
            *ngIf="tierName == tierLevels.Legend"
            alt=""
            src="assets/images/tier-logo/tier_logo_legend.svg"
            draggable="false"
            height="18"
          />
          <img
            *ngIf="tierName == tierLevels.Pro"
            alt=""
            src="assets/images/tier-logo/tier_logo_pro.svg"
            draggable="false"
            height="18"
          />
        </div>

        <ng-container *ngIf="memberCard$ | async as memberCard">
          <div class="text-white font-size-10 mb-2">{{ memberCard.ProductCode }} - {{ memberCard.ExternalRefNumber }}</div>
        </ng-container>
      </div>
      <div *ngIf="memberCard$ | async as memberCard">
        <ng-container *ngIf="memberCard.RetailDiscountLevel != null || memberCard.RetailDiscountLevel != undefined">
          <div
            class="member-details px-3 w-100 py-2 rounded-4"
            [ngClass]="{
              'bg-tertiary': !tierName,
              'bg-pro': tierName == tierLevels.Pro,
              'bg-allstar': tierName == tierLevels.AllStar,
              'bg-legend': tierName == tierLevels.Legend,
              'bg-mvp': tierName == tierLevels.Mvp,
            }"
          >
            <div
              class="d-flex justify-content-evenly align-items-center"
              [ngClass]="{
                'text-primary': tierName == tierLevels.Pro || tierName == tierLevels.Legend,
                'text-white': tierName == tierLevels.AllStar || tierName == tierLevels.Mvp,
              }"
            >
              <ng-container *notInPopulation="[populations.Suite, populations.Pts]">
                <div class="card-id">
                  <label class="text-heading mb-0">{{ pointsBalance$ | async | getNumber }}</label>
                  <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
                </div>
              </ng-container>
              <ng-container *notInPopulation="[populations.Suite, populations.Pts]">
                <div class="card-id" [ngClass]="isFree ? 'px-0' : 'px-4'">
                  <label class="text-heading mb-0">{{ combinedCard$ | async | FortressCurrencyPipe }}</label>
                  <div class="font-size-12">{{ 'member.card.ecash.balance' | translate }}</div>
                </div>
              </ng-container>
              <ng-container *notInPopulation="populations.Free">
                <div class="card-id pe-4">
                  <label class="text-heading mb-0">{{ memberCard.DiscountLevel | getNumber }}%</label>
                  <div class="font-size-12">{{ 'member.card.discountlevel' | translate }}</div>
                </div>
              </ng-container>
              <ng-container *notInPopulation="populations.Free">
                <div class="card-id">
                  <label class="text-heading mb-0">{{ memberCard.RetailDiscountLevel | getNumber }}%</label>
                  <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container font-size-14 mt-2">
        <a class="nav-item" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">home</span>
            <div class="nav-text">{{ 'nav.home' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a class="nav-item" [routerLink]="['/account']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">person</span>
            <div class="nav-text">{{ 'nav.account' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a
          *inPopulation="[populations.Default, populations.Free, populations.Partner]"
          class="nav-item"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text">{{ 'rewards.nav.wallet' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a
          *inPopulation="[populations.Default, populations.Free, populations.Partner]"
          class="nav-item"
          [routerLink]="['/rewards']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">star</span>
            <div class="nav-text">{{ 'nav.rewards' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a
          *inPopulation="[populations.Default, populations.Free, populations.Partner]"
          class="nav-item"
          [routerLink]="['/challenges']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">flag</span>
            <div class="nav-text">{{ 'nav.challenges' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a
          *inPopulation="[populations.Default, populations.Free, populations.Partner]"
          class="nav-item"
          [routerLink]="['/badges']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">local_police</span>
            <div class="nav-text">{{ 'nav.badges' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a
          *inPopulation="[populations.Default, populations.Free, populations.Partner]"
          class="nav-item"
          [routerLink]="['/earn']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">emoji_events</span>
            <div class="nav-text">{{ 'nav.earn' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a
          *inPopulation="[populations.Default, populations.Free, populations.Partner]"
          class="nav-item"
          [routerLink]="['/tier']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">show_chart</span>
            <div class="nav-text">{{ 'nav.tier' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a class="nav-item" [routerLink]="['/contact']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">mail</span>
            <div class="nav-text">{{ 'nav.contact.us' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">article</span>
            <div class="nav-text">{{ 'nav.terms' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">help</span>
            <div class="nav-text">{{ 'nav.faq' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon me-0">navigate_next</div>
          </div>
        </a>

        <a class="nav-item text-white mb-4" (click)="logout()">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">power_settings_new</span>
            <div class="nav-text">{{ 'nav.log.out' | translate }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- Non renewing -->

<div class="nr mobile-nav-menu d-flex position-fixed flex-column d-lg-none" *inPopulation="[populations.NonRenewingPopulation]">
  <div class="mobile-nav-bar d-flex bg-primary align-items-center justify-content-between">
    <a class="nav-item fw-bold brand p-1 nonrenew" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/MyTwins_PrimaryLogo_Light.png" draggable="false" />
    </a>
  </div>
</div>
