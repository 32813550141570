import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AuthService,
  BalanceQuery,
  Card,
  CardQuery,
  MemberCard,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  NotificationCenterQuery,
  NotificationCenterService,
  config,
  PopulationQuery
} from '@fgb/core';
import { Router, NavigationStart } from '@angular/router';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
import { TierLevels } from 'src/app/shared/utilities/tier-levels';

@Component({
  selector: 'fgb-mobile-nav-menu',
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.scss'],
})
export class MobileNavMenuComponent implements OnInit, OnDestroy {
  memberDetails$: Observable<MemberDetails | undefined>;
  memberCard$: Observable<MemberCard | undefined>;
  selectedMember: string;
  isFree: boolean;
  cards$: Observable<Card[]>;
  tierName: string | undefined;
  unseenNotificationCount$: Observable<number>;
  combinedCard$: Observable<number | undefined>;
  tierLevels = TierLevels;
  pointsBalance$: Observable<number | undefined>;
  populations = Populations;
  constructor(
    private authService: AuthService,
    private router: Router,
    private memberQuery: MemberQuery,
    private memberCardQuery: MemberCardQuery,
    private cardQuery: CardQuery,
    private notificationService: NotificationCenterService,
    private notificationQuery: NotificationCenterQuery,
    private balanceQuery: BalanceQuery,
    private populationQuery: PopulationQuery
  ) {}
  private routerSubscription: Subscription;
  public isCollapsed = true;

  ngOnInit() {
    this.tierName = this.memberQuery.getUserContext().tierName
    this.isFree = this.populationQuery.getCurrentPopulation().DisplayName == this.populations.Free
    this.initMemberAccounts();
    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap(() => (this.isCollapsed = true))
      )
      .subscribe();
    this.unseenNotificationCount$ = this.notificationQuery.unseenNotificationsCount$;
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
  }

  initMemberAccounts() {
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.cards$ = this.cardQuery.selectCards();
    this.selectedMember = this.memberQuery.getUserContext().memberId;
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  logout() {
    firstValueFrom(this.authService.logout()).then(() => this.router.navigate(['/login']));
  }

  loginWithCard(memberId: string) {
    firstValueFrom(this.authService.switchUser(memberId));
  }

  toggleNotificationCenter(open: boolean) {
    this.notificationService.updateIsOpen(open);
  }
}
