<div class="full-page w-100 bg-secondary">
  <div class="d-flex align-items-center justify-content-center pt-lg-5 pt-4 pb-3">
    <img alt="" class="card-logo" src="assets/images/login-registration/login-logo.svg" draggable="false" />
  </div>
  <div class="login-page-div bg-white rounded-4">
    <div class="login-page-details-div">
      <div class="panel-text mlb-text font-size-14">
        {{ 'login.page.description' | translate }}
      </div>
      <!-- Login Form -->
      <fgb-mlbam-user-login></fgb-mlbam-user-login>

      <div class="text-center">
        <!-- Registration Details -->
        <a [routerLink]="['/register/new-member']">
          <div class="btn btn-white border-primary btn-block create-account-button w-100 font-size-14 fw-bold rounded-4 my-4">
            <span class="text-primary">{{ 'login.page.create.account.description' | translate }}</span>
          </div>
        </a>

        <div
          class="font-size-14 text-primary text-start"
          [innerHTML]="'login.page.partner.invite.description' | translate | markdownTranslate"
        ></div>
      </div>
      <a [routerLink]="['/register/partner']">
        <div class="btn btn-tertiary btn-block create-account-button w-100 font-size-14 fw-bold rounded-4 my-2">
          <span class="text-white">{{ 'login.page.create.account.button' | translate }}</span>
        </div>
      </a>
      <div class="mlb-links mt-4">
        <a href="{{ 'login.page.privacy.link.1.url' | translate }}">
          <div class="mlb-privacy">
            {{ 'login.page.privacy.link.1' | translate }}
          </div>
        </a>
        <a href="{{ 'login.page.privacy.link.2.url' | translate }}">
          <div class="mlb-privacy">
            {{ 'login.page.privacy.link.2' | translate }}
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
