<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar
    [backButtonLink]="marketplaceItemType.backLink"
    [marketplaceItemGroup]="marketplaceItemType.group"
  ></fgb-points-summary-bar>
</div>

<div class="container mt-3" *ifScreenSize="screenType.Desktop">
  <a class="cursor-pointer d-flex align-items-center" [routerLink]="[marketplaceItemType.backLink]">
    <span class="icon material-icons fw-bold text-system-red">chevron_left</span>
    <span class="font-size-14 fw-bold text-system-red ms-1">{{ 'reward.detail.back' | translate }}</span>
  </a>
</div>

<fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels"></fgbcl-marketplace-details>

