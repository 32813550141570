<div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
  <h1 class="text-white">{{ 'featured.rewards.page.title' | translate }}</h1>
</div>

<div class="container my-3 d-lg-none" *ifScreenSize="screenType.Desktop">
  <fgb-member-card></fgb-member-card>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container my-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<fgb-filter-modal [filterTypesList]="filterTypesList" (filterTypesListEmitter)="handleFilterTypesListEmitter($event)"
  [selectedValues]="selectedValues" (selectedValuesEmitter)="handleSelectedValuesEmitter($event)"
  [showOffCanvas]="showOffCanvas" (showOffCanvasEmitter)="handleShowOffCanvasEmitter()">
</fgb-filter-modal>

<div class="container mt-3">
  <fgbcl-mixed-loyalty-list [onlyFavourites]="true" [filterOptionsList]="selectedValues" [pageSize]="100"></fgbcl-mixed-loyalty-list>
</div>
