<div
  class="answer-container d-flex"
  [ngClass]="{
    selected: answer.UserSelected,
    'flex-column': displayType === answerDisplayType.Image,
    'flex-row': displayType !== answerDisplayType.Image
  }"
>
  <ng-container [ngSwitch]="displayType">
    <!-- Image and Text  -->
    <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
      <img
          *ngIf="answer.ImageURL"
          class="image-container icon"
          src="{{ answer.ImageURL | contentImage : '7' }}"
          alt="{{ answer.ImageURL }}"
        />
        <div class="answer-text-wrapper w-100 progress">
          <span class="poll-percentage">{{ percentage + '%' }}</span>
          <div class="answer-text-landscape text-end">
            {{ answer.Title }}
          </div>
          <div class="answer-text progress-bar text-start fw-bold w-100 h-100">
            <ngb-progressbar [value]="percentage"></ngb-progressbar>
          </div>
        </div>
    </ng-container>

    <!-- Text Only  -->
    <ng-container *ngSwitchCase="answerDisplayType.Text">
        <div class="answer-text-wrapper w-100 progress">
          <span class="poll-percentage">{{ percentage + '%' }}</span>
          <div class="answer-text-landscape text-end">
            {{ answer.Title }}
          </div>
          <div class="answer-text progress-bar text-start fw-bold w-100 h-100">
            <ngb-progressbar [value]="percentage"></ngb-progressbar>
          </div>
        </div>
    </ng-container>

    <!-- Image Only  -->
    <ng-container *ngSwitchCase="answerDisplayType.Image">
      <img
        *ngIf="answer.ImageURL"
        class="image-container"
        src="{{ answer.ImageURL | contentImage : '7' }}"
        alt="{{ answer.ImageURL }}"
      />
      <div class="answer-text-wrapper image-poll progress">
        <span class="poll-percentage w-100 justify-content-center">{{ percentage + '%' }}</span>
        <div class="answer-text progress-bar text-center fw-bold w-100 h-100">
          <ngb-progressbar [value]="percentage"></ngb-progressbar>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="answer-text-bg image-poll"></div>
</div>
