<div class="my-4">
  <div class="survey-answer">
    <div class="survey-answer-container justify-content-center">
      <div class="survey-answer-item w-100" *ngFor="let answer of selectedAnswers">
        <div class="d-flex flex-row">
          <ng-container [ngSwitch]="displayType">
            <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
              <img class="image-container icon" src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
              <div class="answer-text-landscape text-center text-white bg-secondary">
                <span>{{ answer.Title }}</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="answerDisplayType.Text">
              <div class="answer-text-landscape text-center text-white bg-secondary">
                <span>{{ answer.Title }}</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="answerDisplayType.Image">
              <img class="image-container" src="{{ answer.ImageURL | contentImage : '7' }}" alt="{{ answer.ImageURL }}" />
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center fw-bold font-size-16 text-secondary mt-4">Thank you for submitting your selection</div>
  <div class="text-center text-secondary font-size-14 mb-lg-5 mb-3">
    Please come back after the conclusion of this predictor to view your results
  </div>
</div>
