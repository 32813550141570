<form [formGroup]="acceptForm" class="row mt-3" (ngSubmit)="submit()">
  <div class="row w-100">
    <div class="partner-form-title d-flex align-items-center w-100 pb-2">
      <span class="text-center mb-0 font-size-14 font-sans-bold">{{ 'partner.accept.form.title' | translate }}</span>
    </div>
  </div>
  <div class="px-0 row w-100">
    <hr class="partner-divider w-100" />

    <div class="col-6 mt-3 form-floating">
      <input formControlName="code" placeholder="Insert code here" class="custom-field-form form-control field" id="customField" />
      <label class="display-name-custom-field" for="floatingInputCustomField">{{ 'partner.add.form.code.label' | translate }}</label>
    </div>
    <div class="col-6 mt-3 form-floating">
      <input formControlName="secretCode" placeholder="Insert code here" type="email" class="custom-field-form form-control field" id="customField"/>
      <label class="display-name-custom-field" for="floatingInputCustomField">{{ 'partner.accept.form.secretcode.label' | translate }}</label>
    </div>
    <div class="mt-2 px-2 error-message">
      <fgbcl-errors></fgbcl-errors>
    </div>
  </div>

  <div class="button-container d-flex justify-content-end form-buttons mb-3 w-100">
    <button (click)="close(); $event.preventDefault()" class="btn btn-danger fw-bold ms-3">
      {{ 'partner.add.form.cancel.button' | translate }}
    </button>
    <button type="submit" [disabled]="acceptForm.invalid" class="btn btn-success fw-bold me-3">
      {{ 'partner.form.accept.button' | translate }}
    </button>
  </div>
</form>
