<ng-container *ngIf="transactions$ | async as transactions">
  <ng-container *ngIf="transactions$ | async as transactions">
    <div *ngFor="let day of transactions | keyvalue: keepOrder">
      <h5 class="transaction-date mt-4">{{ day.key }}</h5>
      <div class="mb-0 mb-lg-2">
        <div class="card my-3 py-3 px-4 border rounded" *ngFor="let transaction of day.value">
          <div class="row">
            <div class="col-8 text-start">
              <div class="fw-bold text-black transaction-text">
                {{ transaction.TransactionDescription || transaction.DescriptionText }}
              </div>

              <div class="transaction-subtext fw-bold text-info">
                {{ transaction.TransactionDateUTC | UtcToLocaltimePipe: 'hh:mm a' | uppercase }}
              </div>
            </div>
            <div class="col-4 text-end pt-2">
              <div
                class="fw-bold font-size-12 text-capitalize"
                [ngClass]="transaction.Amount >= 0 ? 'text-success' : 'text-error'"
              >
                {{ transaction.Amount >= 0 ? '+' : ''
                }}{{ transaction.Amount | FortressCurrencyPipe: (transactionType === transactionTypes.loyalty ? 'PTS' : 'USD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.loyalty">
      <div class="border rounded p-3 mt-3">
        <p class="text-center font-size-12 mb-0">
          {{ 'no.activity.placeholder.list.title' | translate }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.ecash">
      <div class="border rounded p-3 mt-3">
        <p class="text-center font-size-12 mb-0">
          {{ 'no.activity.placeholder.list.title' | translate }}
        </p>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<div *ngIf="loading" class="mt-4">
  <fgbcl-loading></fgbcl-loading>
</div>
