<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="form-floating my-3">
    <input
      type="email"
      class="form-control rounded-4"
      id="floatingInput"
      placeholder="name@example.com"
      formControlName="username"
    />
    <label for="floatingInput" class="fw-normal">{{ 'form.login.email' | translate }}</label>
  </div>
  <div class="form-floating mb-3">
    <input
      type="password"
      class="form-control rounded-4"
      id="floatingPassword"
      placeholder="Password"
      formControlName="password"
    />
    <label for="floatingPassword" class="fw-normal">{{ 'form.login.password' | translate }}</label>
  </div>

  <div class="d-flex align-items-center justify-content-center">
    <h5 class="font-size-14" [innerHTML]="'login.page.click.here.text' | translate | markdownTranslate"></h5>
  </div>
  <div class="form-group mb-2 mt-2 mt-lg-0">
    <button [disabled]="loading" class="btn btn-primary btn-block login-button font-size-14 w-100 fw-bold rounded-4">
      <span *ngIf="!loading" class="text-white">{{ 'login.page.login.field' | translate }}</span>
      <fgbcl-loading [size]="'small'" *ngIf="loading"></fgbcl-loading>
    </button>
  </div>
  <fgbcl-errors></fgbcl-errors>
</form>
