<div class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1 class="title font-default text-white">{{ 'badges.page.title' | translate }}</h1>
  </div>
</div>

<div class="container my-3 d-lg-none d-block">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-4">
  <fgbcl-badge-overview-list></fgbcl-badge-overview-list>
</div>