import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, config, TranslationLoader, WebPushNotificationService } from '@fgb/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
declare var gtag: any;
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'fgb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private translationLoader: TranslationLoader,
    private webPushNotificationService: WebPushNotificationService
  ) {
    this.translate.setDefaultLang(config.languages[0]);

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.googleAnalyticsTag;
    document.head.prepend(script);

    const navigationEvent = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));

    navigationEvent.subscribe((e) => {
      let event = e as NavigationEnd;
      gtag('config', config.googleAnalyticsTag, { page_path: event.urlAfterRedirects });
    });

    /** Google maps API */
    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + config.googleMapsAPIKey;
    document.head.prepend(googleMapsScript);

    this.translationLoader.loadTranslations().pipe(takeUntil(this._destroyed$)).subscribe();
    // Logs the notification received to the database
    this.webPushNotificationService.subscribe((message: any) => {
      console.log('Message received from server: ', message);
    });
  }
  
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
