import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoadScripts, DynamicScript } from 'src/app/shared/utilities/load-scripts.function';
import { MemberQuery, config, ScorecardService } from '@fgb/core';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

declare var ZoomMtg: any;

@Component({
  selector: 'fgb-zoom-conference',
  templateUrl: './fgb-zoom-conference.component.html',
  styleUrls: ['./fgb-zoom-conference.component.scss'],
})
export class FgbZoomConferenceComponent implements OnInit {
  @Input() meetingNumber: string;
  @Input() meetingPassword: string;
  @Input() apiKey: string;
  @Input() leaveUrl: string;
  @Input() signature: string;
  @Output() onStateChange = new EventEmitter();

  meetingJoinedSuccessfully: boolean = false;

  constructor(
    private memberQuery: MemberQuery,
    private scorecardService: ScorecardService
  ) {}

  ngOnInit() {
    firstValueFrom(
      this.memberQuery.selectMemberDetails().pipe(
        map((x) => {
          if (!x) {
            return;
          }

          LoadScripts([
            { src: 'https://source.zoom.us/1.8.6/lib/vendor/react.min.js' },
            { src: 'https://source.zoom.us/1.8.6/lib/vendor/react-dom.min.js' },
            { src: 'https://source.zoom.us/1.8.6/lib/vendor/redux.min.js' },
            { src: 'https://source.zoom.us/1.8.6/lib/vendor/redux-thunk.min.js' },
            { src: 'https://source.zoom.us/1.8.6/lib/vendor/jquery.min.js' },
            { src: 'https://source.zoom.us/1.8.6/lib/vendor/lodash.min.js' },
            { src: 'https://source.zoom.us/zoom-meeting-1.8.6.min.js' },
          ] as DynamicScript[]).then(() => {
            ZoomMtg.setZoomJSLib('https://source.zoom.us/1.8.6/lib', '/av');
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareJssdk();

            let meetingNumberLocale = this.meetingNumber;
            let meetingPasswordLocale = this.meetingPassword;
            let apiKeyLocale = this.apiKey;
            let userEmailLocale = x.EmailAddress1;
            let userUserNameLocale = x.FirstName + ' ' + x.Surname;
            let signatureLocale = this.signature;

            if (this.leaveUrl == null) {
              this.leaveUrl = config.portalUrl;
            }

            ZoomMtg.init({
              debug: true,
              leaveUrl: this.leaveUrl,
              disableJoinAudio: false,
              loginWindow: {
                // optional,
                width: 400,
                height: 380,
              },
              success: function () {
                ZoomMtg.join({
                  signature: signatureLocale,
                  meetingNumber: meetingNumberLocale,
                  userName: userUserNameLocale,
                  apiKey: apiKeyLocale,
                  userEmail: userEmailLocale,
                  passWord: meetingPasswordLocale,
                  success: (responseMessage: any) => {
                    console.log(responseMessage);
                    this.meetingJoinedSuccessfully = true;
                  },
                  error: (errorMessage: any) => {
                    console.log(errorMessage);
                  },
                });
              },
            });
          });
        })
      )
    ).finally(() => {
      if (this.meetingJoinedSuccessfully) {
        firstValueFrom(this.scorecardService.joinWebCall(this.meetingNumber));
      }
    });
  }
}
