<div>
  <div class="row mx-0">
    <div class="col-1 pe-0 w-auto">
      <div class="row mx-0">
        <div class="col-1 d-flex justify-content-center">
          <div class="circle-icon-border w-auto">
            <div class="icon material-icons" aria-hidden="true" [ngClass]="{ 'text-success': tierId >= Tier.id }">
              check_circle
            </div>
          </div>
        </div>
      </div>
      <div class="row h-50px mx-0" *ngIf="!isLast">
        <div class="col-1 d-flex justify-content-center">
          <div class="tier-line"></div>
        </div>
      </div>
    </div>
    <div class="col-lg-11 col-9 ps-0 tier-detail">
      <div class="mt-2">
        <img
          class="tier-image"
          [src]="'assets/images/tier-logo/tier_logo_text_' + Tier.name + '.svg'"
          draggable="false"
          height="18"
        />
        <div class="font-size-12 mt-2 {{ Tier.name }}">
          {{ Tier.description | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
