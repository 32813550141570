<div class="card bg-primary text-on-primary shadow">
  <div class="card-body p-3">
    <div class="d-flex align-items-center">
      <div class="d-inline-block pe-2">
        <div class="svg-user svg activity fw-bold" [inlineSVG]="'assets/images/icons/account-icon.svg'"></div>
      </div>
      <div class="child-panel d-inline-block">
        <h4 class="m-0 fw-bold font-size-14">{{ child.Firstname }} {{ child.Lastname }}</h4>
        <p class="m-0 font-size-12">
          <ng-container *ngIf="child.MembershipOptionId === 1"> All Star </ng-container>
          <ng-container *ngIf="child.MembershipOptionId === 0"> Rookie </ng-container>
        </p>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-5">
        <label class="mb-0">Points</label>
        <div *ngIf="loyaltyPointsCard$" class="d-flex">
          <span
            class="icon material-icons marketplace-icons text-white child-detail-icon me-2"
            role="img"
            attr.aria-label="{{ 'nav.points.screenreader' | translate }}"
            >emoji_events</span
          >
          <span class="font-size-14">
            {{ loyaltyPointsCard$ | async | FortressCurrencyPipe : '' : '' : '1.0-0' }}
          </span>
        </div>
      </div>
      <div class="col-7">
        <label class="mb-0">Date of Birth</label>
        <div class="d-flex">
          <span
            class="icon material-icons marketplace-icons text-white child-detail-icon me-2"
            role="img"
            attr.aria-label="{{ 'nav.points.screenreader' | translate }}"
            >calendar_month</span
          >
          <span class="font-size-14">
            {{ child.DateOfBirth | date }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-lg-flex d-none justify-content-between p-2 shadow login-panel">
  <ng-container *ngIf="enableLoginButton">
    <button
      type="button"
      (click)="loginToChild()"
      class="btn btn-secondary child-barcode-btn fw-bold fw-bold text-white"
    >
      Login
    </button>
  </ng-container>

  <ng-container *ngIf="child.Barcode && enableAttachLostBarcodeButton">
    <button class="btn btn-primary child-barcode-btn fw-bold text-white font-size-14" (click)="linkNewBarcode(true)">
      {{ 'child.panel.lost.card' | translate }}
    </button>
  </ng-container>
  <ng-container *ngIf="!child.Barcode && enableAttachLostBarcodeButton">
    <button class="btn btn-secondary child-barcode-btn fw-bold text-white font-size-14" (click)="linkNewBarcode()">
      {{ 'child.panel.attach' | translate }}
    </button>
  </ng-container>
</div>

<div class="d-flex d-lg-none justify-content-between p-2 shadow bg-white rounded-bottom login-kids-panel">
  <button
    type="button"
    (click)="loginToChild()"
    class="btn btn-secondary mobile-child-login-btn fw-bold text-white font-size-14"
  >
    Login
  </button>

  <button
    type="button"
    (click)="openChildBarcode()"
    class="btn btn-secondary mobile-child-login-btn fw-bold text-white font-size-14"
  >
    Show Barcode
  </button>
</div>
