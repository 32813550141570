import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartnerService, ErrorStateService, AuthService } from '@fgb/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
@Component({
  selector: 'fgb-partner-accept-form',
  templateUrl: './partner-accept-form.component.html',
  styleUrls: ['./partner-accept-form.component.scss'],
})
export class PartnerAcceptFormComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private partnerService: PartnerService,
    private errorService: ErrorStateService,
    private authService: AuthService,
    private activeModal: NgbActiveModal
  ) {}
  acceptForm: FormGroup;
  //@Output() close = new EventEmitter();

  ngOnInit() {
    this.acceptForm = this.fb.group({
      code: ['', Validators.required],
      secretCode: ['', Validators.required],
    });
  }
  closeModal(): void {
    this.activeModal.close();
  }
  close(): void {
    this.errorService.clearErrors();
    this.closeModal();
  }
  submit(): void {
    const code: string = this.acceptForm.value.code;
    const secretCode: string = this.acceptForm.value.secretCode;
    firstValueFrom(this.partnerService.confirmPartner(code, secretCode))
      .then(() => {
        firstValueFrom(this.authService.refreshAccessToken()).then(() => {
          // reload to refresh theme and data
          location.reload();
        });
      })
      .catch((t) => {
        this.errorService.clearErrors();
        this.errorService.addError('Please enter valid partner credentials');
      });
  }
}
