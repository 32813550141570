<div class="full-page w-100 bg-secondary">
  <div class="d-flex align-items-center justify-content-center pt-5 pb-3">
    <img alt="" class="card-logo" src="assets/images/login-registration/login-logo.svg" draggable="false" />
  </div>
  <div class="login-page-div bg-white rounded-4 mt-lg-2 mt-4">
    <div class="login-page-details-div text-center">
      <!-- Login Form -->
      <div class="icon material-icons maintenance-page-icon text-secondary" aria-hidden="true">engineering</div>
      <h5
        class="maintenance-page-heading text-heading"
        [innerHTML]="'maintenancepage.currentlyunavailable.text' | translate | markdownTranslate"
      ></h5>
      <p class="font-size-14 text-default" [innerHTML]="'maintenancepage.currentlyunavailable.subheading' | translate | markdownTranslate"></p>
    </div>
  </div>
</div>
