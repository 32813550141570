import { Component, Input, OnInit } from '@angular/core';
import { ProcessProviderId, RewardsWalletItem, RewardsWalletQuery, RewardsWalletService } from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'fgb-wallet-claimed-page',
  templateUrl: './wallet-claimed-page.component.html',
  styleUrls: ['./wallet-claimed-page.component.scss'],
})
export class WalletClaimedPageComponent implements OnInit {
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  walletItems$: Observable<RewardsWalletItem[]>;

  walletItemsClaimed$: Observable<RewardsWalletItem[]>;

  constructor(
    private rewardWalletItemsService: RewardsWalletService,
    private rewardsWalletQuery: RewardsWalletQuery
  ) {}

  ngOnInit() {
    firstValueFrom(this.rewardWalletItemsService.fetchWalletItems());
    this.walletItemsClaimed$ = this.rewardsWalletQuery.selectRewardWalletItemsByStatus(true);
  }
}
