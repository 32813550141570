import { Component, OnInit } from '@angular/core';
import { MemberQuery } from '@fgb/core';

@Component({
  selector: 'fgb-tier-level-page',
  templateUrl: './tier-level-page.component.html',
  styleUrl: './tier-level-page.component.scss'
})

export class TierLevelPageComponent implements OnInit {
  tierId: number | undefined;
  tierLevels = [
    { id: 2, name: 'pro', description: 'tier.pro.description' },
    { id: 3, name: 'all_star', description: 'tier.all_star.description' },
    { id: 4, name: 'mvp', description: 'tier.mvp.description' },
    { id: 5, name: 'legend', description: 'tier.legend.description' },
  ];

  constructor(private memberQuery: MemberQuery) {}

  ngOnInit() {
    this.tierId = this.memberQuery.getUserContext().tierId
  }

}
