<div class="full-page w-100 d-md-flex d-block px-0">
  <!--start Side-->
  <img class="shared-login-banner d-md-block d-none" draggable="false" src="assets/images/login-registration/login-banner.png" />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="p-4 right-side-login">
    <form class="change-password container" (submit)="submit()" [formGroup]="formChangePassword">
      <h1 class="change-password-title my-5 text-center">{{ 'change.pw.title' | translate }}</h1>
      <div class="form-input">
        <label>{{ 'change.pw.label' | translate }}</label>
        <div class="d-flex justify-content-between align-items-center position-relative">
          <input class="form-control" [type]="visiblePassword ? 'text' : 'password'" formControlName="password" />
          <span class="visible-icon material-icons cursor-pointer" (click)="toggleVisiblePassword()" *ngIf="visiblePassword">
            visibility
          </span>
          <span class="visible-icon material-icons cursor-pointer" (click)="toggleVisiblePassword()" *ngIf="!visiblePassword">
            visibility_off
          </span>
        </div>
        <div class="text-danger font-size-12 mt-2" [style.visibility]="checkForm['password'] ? '' : 'hidden'">
          {{ 'change.pw.warn.valid' | translate }}
        </div>
        <label>{{ 'change.pw.label.confirm' | translate }}</label>
        <div class="d-flex justify-content-between align-items-center position-relative">
          <input class="form-control" [type]="visibleConfirm ? 'text' : 'password'" formControlName="confirmPassword" />
          <span class="visible-icon material-icons cursor-pointer" (click)="toggleVisibleConfirm()" *ngIf="visibleConfirm">
            visibility
          </span>
          <span class="visible-icon material-icons cursor-pointer" (click)="toggleVisibleConfirm()" *ngIf="!visibleConfirm">
            visibility_off
          </span>
        </div>
        <div
          class="text-danger font-size-12 mt-2"
          [style.visibility]="checkForm['confirmPassword'] || !isPasswordSameConfirm ? '' : 'hidden'"
        >
          {{ 'change.pw.warn.confirm' | translate }}
        </div>
        <div class="btn-submit mt-3">
          <button type="submit" [disabled]="formChangePassword.invalid || !isPasswordSameConfirm" class="w-100 btn btn-success">
            {{ 'change.pw.btn' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<ng-template #alert let-modal>
  <div class="modal-body">
    <div class="text-center mt-4">{{ contentModal }}</div>
  </div>
  <div class="modal-footer border-top-0">
    <button type="button" class="btn btn-success" (click)="modal.close()">OK</button>
  </div>
</ng-template>
