<h5 class="mt-4 mb-3 title-partner" *inPopulation="[populations.Default]">{{ 'partner.list.title' | translate }}</h5>
<h5 class="mt-4 mb-3 title-partner" *inPopulation="[populations.Partner]">{{ 'partner.list.partner.title' | translate }}</h5>

<div class="card card-primary my-2 bg-white font-size-14">
  <div class="card-body pt-2">
    <div class="row text-start details-header py-2 mx-0">
      <div class="col-10 col-lg-3 d-lg-none d-block">
        <label class="fw-bold text-black">{{ 'partner.details' | translate }}</label>
      </div>
      <div class="col-4 d-lg-block d-none">
        <label class="fw-bold text-black">{{ 'partner.fullname' | translate }}</label>
      </div>
      <div class="col-5 d-lg-block d-none">
        <label class="fw-bold text-black">{{ 'partner.email' | translate }}</label>
      </div>
      <div class="col-2 d-lg-block d-none">
        <label class="fw-bold text-black">{{ 'partner.status' | translate }}</label>
      </div>
      <ng-container *notInPopulation="[populations.Free]">
        <div class="col-1">
          <label class="fw-bold text-black">{{ 'partner.action' | translate }}</label>
        </div>
      </ng-container>
    </div>
    <div *ngFor="let partner of approvedPartners$ | async" class="row text-start align-items-center my-lg-0 py-3 my-3 mx-0">
      <div class="col-lg-4 col-10 partner-info">
        <div>{{ partner.FullName }}</div>
        <div class="d-flex d-lg-none flex-column">
          <div>{{ partner.Email }}</div>
          <div class="text-success fw-bold">Partner</div>
        </div>
      </div>
      <div class="col-5 d-lg-block d-none partner-info">
        <div class="text-primary">{{ partner.Email }}</div>
      </div>
      <div class="col-2 d-lg-block d-none partner-info">
        <div class="text-success fw-bold">Partner</div>
      </div>
      <div class="col-1" *inPopulation="[populations.Default]">
        <button
          class="btn text-grey p-lg-0"
          confirmModal
          [confirmContent]="confirmContent"
          [confirmContext]="partner"
          (confirm)="delete(partner.RelationshipId)"
          (click)="selectPartner(partner.FullName, partner.Email)"
          aria-label="Delete partner"
        >
          <span class="icon material-icons"> delete_forever</span>
        </button>
      </div>
      <ng-template #confirmContent>
        <div class="row w-100 mx-0">
          <div class="d-flex align-items-center w-100 font-size-14 font-sans-bold border-bottom pb-2 mb-3">
            {{ 'partner.list.modal.delete.title' | translate }}
          </div>
          <div class="w-100">
            {{ 'partner.list.modal.delete.message' | translate }}
          </div>
          <div class="d-flex w-100 mt-2 mb-2">
            <span class="font-sans-bold">{{ partnerName }} </span>
            <span class="text-primary">&nbsp;({{ partnerEmail }})</span>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngFor="let partner of pendingPartners$ | async" class="row text-start align-items-center my-lg-0 py-2 my-3 mx-0">
      <div class="col-lg-4 col-10 partner-info">
        <div class="text-info">{{ partner.FullName }}</div>
        <div class="d-flex d-lg-none flex-column">
          <div class="text-info">{{ partner.Email }}</div>
          <div class="text-info fw-bold">Pending</div>
        </div>
      </div>
      <div class="col-5 d-lg-block d-none partner-info">
        <div class="text-grey">{{ partner.Email }}</div>
      </div>
      <div class="col-2 d-lg-block d-none partner-info">
        <div class="text-grey fw-bold">Pending</div>
      </div>
      <div class="col-1" *notInPopulation="[populations.Free]">
        <button
          class="btn text-grey p-lg-0"
          confirmModal
          [confirmContent]="confirmContent"
          (confirm)="deletePending(partner.TicketKey)"
          (click)="selectPartner(partner.FullName, partner.Email)"
          aria-label="Delete partner"
        >
          <span class="icon material-icons"> delete_forever</span>
        </button>
      </div>
      <ng-template #confirmContent>
        <div class="row w-100 mx-0">
          <div class="d-flex align-items-center w-100 font-size-14 font-sans-bold border-bottom pb-2 mb-3">
            {{ 'partner.list.modal.delete.title' | translate }}
          </div>
          <div class="w-100">
            {{ 'partner.list.modal.delete.message' | translate }}
          </div>
          <div class="d-flex w-100 mt-2 mb-2">
            <span class="font-sans-bold">{{ partnerName }} </span>
            <span class="text-primary">&nbsp;({{ partnerEmail }})</span>
          </div>
        </div>
      </ng-template>
    </div>
    <ng-container
      *ngIf="{
        approvedPartners: approvedPartners$ | async,
        pendingPartners: pendingPartners$ | async
      } as pt"
    >
      <ng-container *ngIf="!pt.approvedPartners.length && !pt.pendingPartners.length">
        <div class="text-center pt-3 font-size-14">{{ 'no.partner.placeholder' | translate }}</div>
      </ng-container>
    </ng-container>
  </div>
</div>
