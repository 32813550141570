import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { zip } from 'rxjs';
import { MarketplaceService, AuctionService, LottoService } from '@fgb/core';
@Component({
  selector: 'fgb-rewards-page',
  templateUrl: './rewards-page.component.html',
  styleUrls: ['./rewards-page.component.scss']
})
export class RewardsPageComponent implements OnInit {
  constructor(private marketplaceService: MarketplaceService, private lottoService: LottoService, private auctionService: AuctionService ) {}
  filterTypesList: { label: string, value: string }[] = [{label: 'reward.filter_modal.all', value: 'all'}];
  selectedValues: string[] = ['all'];
  showOffCanvas: boolean = false;
  screenType = ScreenType;

  ngOnInit() {
    zip(
      this.auctionService.fetchAuctions(),
      this.lottoService.fetchLottos(),
      this.marketplaceService.fetchMarketplaceItems()
    ).toPromise();
  }

  handleFilterTypesListEmitter(filterTypes: {label: string, value: string}[]) {
    this.filterTypesList = [...filterTypes];
  }

  handleSelectedValuesEmitter(selectedValues: string[]) {
    this.selectedValues = [...selectedValues];
  }

  handleShowOffCanvasEmitter() {
    this.showOffCanvas = !this.showOffCanvas;
  }
}
