import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorStateService, PartnerPending, PartnerPendingQuery, PartnerPendingService } from '@fgb/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { Regex } from 'src/app/shared/utilities/regex';

@Component({
  selector: 'fgb-partner-add-form',
  templateUrl: './partner-add-form.component.html',
  styleUrls: ['./partner-add-form.component.scss'],
})
export class PartnerAddFormComponent implements OnInit, OnDestroy {
  addForm: FormGroup;
  pendingPartners$: Observable<PartnerPending[]>;
  pendingPartnerEmails: string[] = [];
  pendingPartnerSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private partnerPendingService: PartnerPendingService,
    private pendingQuery: PartnerPendingQuery,
    private errorService: ErrorStateService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.addForm = this.fb.group({
      fullName: ['', [Validators.pattern(/[\S]/g), Validators.required]],
      email: ['', [Validators.pattern(Regex.email), Validators.required]],
    });

    this.pendingPartnerSub = this.pendingQuery.selectAll().subscribe((pendingPartners) => {
      pendingPartners.forEach((pendingPatner) => {
        this.pendingPartnerEmails.push(pendingPatner.Email.toLowerCase());
      });
    });
  }

  ngOnDestroy(): void {
    if (this.pendingPartnerSub) {
      this.pendingPartnerSub.unsubscribe();
    }
  }

  submit(): void {
    this.errorService.clearErrors();
    let pendingExists = false;
    this.pendingPartnerEmails.forEach((pendingEmail) => {
      if (this.addForm.controls.email.value.toLowerCase() === pendingEmail) {
        pendingExists = true;
        return;
      }
    });

    if (pendingExists) {
      this.errorService.addError('This email is already used for an existing pending partner');
    } else {
      const fullName: string = this.addForm.value.fullName;
      const email: string = this.addForm.value.email;
      if (!fullName || !email || !fullName.trim() || !email.trim()) {
        this.errorService.addError('Full name and email cannot be blank');
        return;
      } else if (/\+/.test(email)) {
        this.errorService.addError('Email cannot contain letter "+"');
        return;
      } 
      firstValueFrom(this.partnerPendingService.add(fullName, email, '')).then(() => this.closeModal());
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

  close(): void {
    this.errorService.clearErrors();
    this.closeModal();
  }
}
