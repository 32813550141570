<div class="banner d-lg-flex d-none" role="banner">
  <h1 class="text-white">{{ 'ecash.title.transfer' | translate }}</h1>
</div>

<div class="container text-center mt-4 border rounded p-3" *ngIf="transferAccounts$ | async as transferAccounts">
  <div class="border-bottom mb-2 font-size-14 text-success font-sans-bold pb-2">{{ 'ecash.title.transfer.success' | translate }}</div>
  <div class="font-size-14 font-sans-bold">
    <span class="text-error">{{transferAccounts.EmailAddress}}</span> 
    {{'ecash.transfer.success.description' | translate}} 
    <span class="text-success">{{transferAccounts.TransferAmount | FortressCurrencyPipe: 'USD' }}</span>
  </div>
  <a class="btn border-0 font-sans-bold text-white mt-5 bg-error rounded" routerLink="/arenacashtransfer">
    {{'ecash.transfer.success.back' | translate}}
  </a>
</div>
