

<ng-container *ngIf="cardLinkOfferDetail$ | async as cardLinkOfferDetails">
  <div class="container px-0">
    <fgbcl-card-link-offer-details
      [cardLinkOfferDtail]="cardLinkOfferDetails"
      [clubImageUrl]="'assets/images/logo-small.svg'"
    ></fgbcl-card-link-offer-details>
  </div>

  <div class="container">
    <fgbcl-card-link-offer-locations [options]="{ showLocations: cardLinkOfferDetails?.LocationType === 1 }" [offerId]="offerId" [latitude]="53.799299" [longitude]="-1.5468876"></fgbcl-card-link-offer-locations>
  </div>
</ng-container>

<div class="container">
  <fgbcl-card-link-offers-list
    [cardLinkOfferOptions]="cardLinkOfferOptionsOtherOffers"
    [labelName]="'card.linking.other.offer.label'"
  ></fgbcl-card-link-offers-list>
  <fgbcl-card-link-offers-list
    [cardLinkOfferOptions]="cardLinkOfferOptionsRecommendedOffers"
    [labelName]="'card.linking.offer.you.may.like.label'"
  ></fgbcl-card-link-offers-list>
  <div class="mt-3">
    <a class="text-danger fw-bold d-flex align-item-center" [routerLink]="'/affiliate'">
      <span class="icon material-icons" aria-hidden="true">arrow_back_ios</span>
      {{ 'card.linking.offer.detail.back.label' | translate }}
    </a>
  </div>
</div>
