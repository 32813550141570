<div class="card b-0 mt-3 mt-lg-0 border rounded">
  <div class="card-body p-0">
    <div class="toggle-container p-3 px-lg-3 px-0 pb-0 row-mb">
      <button (click)="setTransactionType(type.loyalty)" class="toggle-transaction me-lg-3 col-mb-6" [ngClass]="{'button-active' : transactionType == type.loyalty}">
        {{'button.toggle.point' | translate}}
      </button>
      <button (click)="setTransactionType(type.ecash)" class="toggle-transaction col-mb-6 px-0" [ngClass]="{'button-active' : transactionType == type.ecash}">
        {{'button.toggle.ecash' | translate}}
      </button>
    </div>
    <div class="row p-3">
      <div class="col-12 col-lg-7">
        <fgbcl-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgbcl-date-range-picker>
      </div>
      <div class="col-12 col-lg-3 offset-lg-2">
        <button
          type="button"
          class="search-button fw-bold mb-2 d-block mx-auto border-0 rounded text-white bg-primary w-100"
          (click)="search()"
        >
          {{ 'trans.history.search.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<fgb-transaction-list
  *ngIf="selectedValues"
  [startDate]="selectedValues.start"
  [endDate]="selectedValues.end"
  [memberId]="memberId"
  [transactionType]="selectedValues.type"
></fgb-transaction-list>
