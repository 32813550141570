import { Component, OnInit } from '@angular/core';
import { PortalProductsService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-subscriptions-page',
  templateUrl: './subscriptions-page.component.html',
  styleUrls: ['./subscriptions-page.component.scss'],
})
export class SubscriptionsPageComponent implements OnInit {
  constructor(private portalProductsService: PortalProductsService) {}

  ngOnInit() {
    firstValueFrom(this.portalProductsService.fetchPortalProducts());
  }
}
