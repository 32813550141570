import { Component, OnInit } from '@angular/core';
import {
  MemberQuery,
  AutoTopUpModel,
  AutoTopUpSubscriptionModel,
  AutoTopupQuery,
  AutoTopupService,
  config,
  EcashService,
  TopUpAmountTypeId,
} from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'fgb-autotopup',
  templateUrl: 'autotopup.component.html',
  styleUrls: ['./autotopup.component.scss'],
})
export class AutotopupComponent implements OnInit {
  amounts$: Observable<number[]>;
  amount: number;
  autoTopUpSubscription$: Observable<AutoTopUpSubscriptionModel>;
  subcriptionActive: boolean;
  belowAmount: number;
  topUpAmount: number;
  hasSubscription$: Observable<boolean | undefined>;

  constructor(
    private ecashService: EcashService,
    private autoTopupService: AutoTopupService,
    private autoTopupQuery: AutoTopupQuery,
    private memberQuery: MemberQuery,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.amounts$ = this.ecashService.getTopUpAmounts(TopUpAmountTypeId.TopUpAmount).pipe(
      tap((amounts) => {
        if (amounts.length > 0) {
          this.amount = amounts[0];
        }
      })
    );
    firstValueFrom(this.autoTopupService.fetchAutoTopUpSubscriptions());
    this.autoTopUpSubscription$ = this.autoTopupQuery.select().pipe(
      tap((amounts) => {
        if (amounts.AutoTopUpItems && amounts.AutoTopUpItems.length > 0) {
          this.belowAmount = amounts.AutoTopUpItems[0].Threshold;
          this.topUpAmount = amounts.AutoTopUpItems[0].Amount;
        } else {
          if (
            amounts &&
            amounts.AutoTopUpTresholds &&
            amounts.AutoTopUpTresholds.length > 0 &&
            amounts.AutoTopUpAmounts &&
            amounts.AutoTopUpAmounts.length > 0
          ) {
            this.belowAmount = amounts.AutoTopUpTresholds[0];
            this.topUpAmount = amounts.AutoTopUpAmounts[0];
          }
        }
      })
    );

    this.hasSubscription$ = this.autoTopupQuery.select().pipe(
      map((amounts) => {
        if (amounts.AutoTopUpItems && amounts.AutoTopUpItems.length > 0) {
          return amounts.AutoTopUpItems[0].HasSubscription;
        } else {
          return false;
        }
      })
    );
  }

  updateSubscription(currentTopUpAmount: number, currentBelowAmount: number) {
    let user = this.memberQuery.getValue().user;

    if (user) {
      let model: AutoTopUpModel = {
        Active: true,
        Amount: this.topUpAmount ? this.topUpAmount : currentTopUpAmount,
        Threshold: this.belowAmount ? this.belowAmount : currentBelowAmount,
        PortalId: user.portalId,
        MemberId: user.memberId,
        HasSubscription: true,
        SuccessUrl: config.portalUrl + '/ecash/result',
        FailureUrl: config.portalUrl + '/ecash/result',
      };

      firstValueFrom(this.autoTopupService.updateSubscription(model));
      this.modalService.dismissAll();
    }
  }

  toggleSubscription(currentActiveStatus: boolean, belowAmount: number, topUpAmount: number) {
    let user = this.memberQuery.getValue().user;

    if (user) {
      let model: AutoTopUpModel = {
        Active: !currentActiveStatus,
        Amount: topUpAmount,
        Threshold: belowAmount,
        PortalId: user.portalId,
        MemberId: user.memberId,
        HasSubscription: true,
        SuccessUrl: config.portalUrl + '/ecash/result',
        FailureUrl: config.portalUrl + '/ecash/result',
      };

      firstValueFrom(this.autoTopupService.updateSubscription(model));
      this.modalService.dismissAll();
    }
  }

  deleteSubscription() {
    let user = this.memberQuery.getValue().user;

    if (user) {
      let model: AutoTopUpModel = {
        Amount: 0,
        Threshold: 0,
        PortalId: user.portalId,
        MemberId: user.memberId,
        SuccessUrl: config.portalUrl + '/ecash/result',
        FailureUrl: config.portalUrl + '/ecash/result',
      };

      firstValueFrom(this.autoTopupService.deleteSubscription(model));
      firstValueFrom(this.autoTopupService.fetchAutoTopUpSubscriptions());
      this.modalService.dismissAll();
    }
  }

  enableDisableToggle(event: any) {
    this.subcriptionActive = event.target.checked;
  }

  open(content: any) {
    let user = this.memberQuery.getValue().user;

    if (user) {
      let model: AutoTopUpModel = {
        Amount: this.topUpAmount,
        Threshold: this.belowAmount,
        PortalId: user.portalId,
        MemberId: user.memberId,
        SuccessUrl: config.portalUrl + '/ecash/result?autotopup=true',
        FailureUrl: config.portalUrl + '/ecash/result',
      };

      this.autoTopupService.autoTopUp(model);
    }
    this.modalService.open(content);
  }

  onBelowChange(amount: number) {
    this.belowAmount = amount;
  }

  onAmountChange(amount: number) {
    this.topUpAmount = amount;
  }
}
