<div class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1 class="title font-default">{{ 'challenges.page.title' | translate }}</h1>
  </div>
</div>

<div class="container my-3 d-lg-none d-block">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="pt-lg-5 pt-4">
  <fgbcl-scorecard-list-view [icon]="'chevron_right'" [showPlaceholder]="true"></fgbcl-scorecard-list-view>
</div>
