<div class="full-page bg-secondary w-100 d-flex justify-content-center">
  <div class="mw-layout">
    <div class="d-flex align-items-center justify-content-center pt-lg-5 pt-4 pb-3">
      <img alt="" class="card-logo" src="assets/images/login-registration/login-logo.svg" draggable="false" />
    </div>
    <div class="user-login-panel text-start bg-white">
      <div>
        <div class="row h-100">
          <div class="col-12">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>