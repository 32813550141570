<div class="d-none d-lg-block">
  <div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
    <h1 class="text-white">{{ 'wallet.page.title' | translate }}</h1>
  </div>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container my-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<!-- Campaign Offers -->
<div class="offers-container container mb-3">
  <fgbcl-campaigns-offers-list (dataChange)="onCampaignOfferListChange($event)"></fgbcl-campaigns-offers-list>
</div>
<!-- V2 Wallet -->
<div class="wallet-redeem-carousel" [ngClass]="{ free: currentPopulation == 'Free' || currentPopulation == 'Partners' }">
  <div class="container mt-3">
    <ng-container *ngIf="walletItems?.length > 0; else noWalletItems">
      <div class="wallet-redeem-carousel">
        <div class="container">
          <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems"></fgbcl-rewards-wallet-redeem-list>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mb-3 fw-bold font-size-25">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="border rounded">
      <div class="no-wallet-items-description fw-bold font-size-12 text-center text-grey py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text fw-bold" [routerLink]="['/rewards/claimed']">
    {{ 'rewards.wallet.view.claimed' | translate }}
  </a>
</div>
