<div class="container">
  <form *ngIf="transferCashForm" [formGroup]="transferCashForm">
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-lg-5 col-12">
          <label class="font-size-12 fw-bold" for="emailAddress">{{'ecash.email' | translate}}</label>
          <input
            type="text"
            formControlName="emailAddress"
            id="emailAddress"
            placeholder="Enter recipients email"
            class="form-control font-size-14"
          />
          <div *ngIf="submitted && f.emailAddress.errors" class="alert alert-danger font-size-14">
            <div *ngIf="f.emailAddress.errors.required">{{'ecash.email.required' | translate}}</div>
            <div *ngIf="f.emailAddress.errors.pattern">{{'ecash.email.incorrect' | translate}}</div>
          </div>
        </div>
        <div class="col-lg-3 col-12 mt-lg-0 mt-3">
          <label class="font-size-12 fw-bold" for="amount">{{'ecash.amount' | translate}}</label>
          <input
            currencyMask
            formControlName="amount"
            placeholder="{{ 0 | FortressCurrencyPipe : 'USD' }}"
            id="amount"
            class="form-control rounded font-size-14"
          />
          <div *ngIf="submitted && f.amount.errors" class="alert alert-danger font-size-14">
            <div *ngIf="f.amount.errors.required">{{'ecash.amount.required' | translate}}</div>
            <div *ngIf="f.amount.errors.pattern">{{'ecash.amount.number.only' | translate}}</div>
          </div>
        </div>
        <div class="col-lg-2 col-12 offset-lg-2">
          <button
            type="submit"
            class="btn btn-send font-size-14 fw-bold bg-secondary text-white w-100"
            confirmModal
            [disabled]="!transferCashForm.valid"
            [confirmContent]="confirmContent"
            (confirm)="verifyEmail()"
            [ngClass]="{ 'bg-info': !transferCashForm.valid }"
          >
            Send
          </button>
        </div>

        <!--Confirm Modal-->
        <ng-template #confirmContent>
          <div class="confirm-modal-header font-sans-bold d-flex justify-content-between">
            <div>
              {{'ecash.title.modal.transfer' | translate}}
            </div>
            <div class="icon material-icons text-info cursor-pointer" aria-hidden="true" (click)="dismissModal()">close</div>
          </div>
          <div class="modal-body-content font-sans-bold">
            <span>
              {{'transfer.cash.confirm.text' | translate}}
            </span> 
            <span class="text-success">
              {{ transferCashForm.value.amount | FortressCurrencyPipe: 'USD' }}
            </span>
            <span>
              {{'transfer.cash.confirm.to.text' | translate}}
            </span>
            <span class="text-error">
              {{ transferCashForm.value.emailAddress }}
            </span>
          </div>
        </ng-template>
      </div>

      <!-- Can't Transfer to Self Modal -->
      <ng-template #transferOwnEmail let-modal>
        <div class="modal-body">
          <div class="modal-title text-center mt-3">Transfer Failed</div>
          <p class="mb-4 p-4 font-size-16 text-black text-center">You cannot transfer to your own email address</p>
          <button
            type="button"
            class="modal-return rounded d-block ms-auto me-auto bg-secondary text-white font-size-14 fw-bold text-center border-0 fail"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            Back to Funds
          </button>
        </div>
      </ng-template>

      <!-- Transfer Fail Modal -->
      <ng-template #transferFailed let-modal>
        <div class="modal-body">
          <div class="modal-title text-center mt-3">Transfer Failed</div>
          <p class="mb-4 p-4 font-size-16 text-black text-center">
            No PayPal SixthMan Member can be found with this email…<br /><br />
            Recipient must have a Phoenix Suns Account Manager account to receive Arena Cash. Please have recipient download the
            Official Suns App and create an Account Manager account
          </p>
          <button
            type="button"
            class="modal-return rounded d-block ms-auto me-auto bg-secondary text-white font-size-14 fw-bold text-center border-0 fail"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            Back to Funds
          </button>
        </div>
      </ng-template>

      <!-- Not Enough Funds Modal -->
      <ng-template #invalidFunds let-modal>
        <div class="modal-body">
          <p class="mb-4 font-size-16 text-black text-center">
            Unfortunately you do not have enough money to send to <br />
            <span class="text-primary">{{ transferCashForm.value.emailAddress }}</span> the amount of
            <span class="text-secondary">{{ transferCashForm.value.amount | FortressCurrencyPipe: 'USD' }}</span>
          </p>
          <button
            type="button"
            class="modal-return rounded d-block ms-auto me-auto bg-secondary text-white font-size-14 fw-bold text-center border-0"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            Return to Arena Cash
          </button>
        </div>
      </ng-template>

      <fgbcl-errors></fgbcl-errors>

      <ng-container *ngIf="loading">
        <div class="loading-transactions">
          <fgbcl-loading></fgbcl-loading>
        </div>
      </ng-container>
    </div>
  </form>
</div>
