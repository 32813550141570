<div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
  <h1 class="text-white">
    {{'partner.page.title' | translate}}
  </h1>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container my-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgb-account-nav></fgb-account-nav>

<div *ngIf="partnerLimit$ | async as partnerLimit" class="container">
  <ng-container *notInPopulation="[populations.Partner]">
    <div class="mb-4 mt-2 mt-lg-0">
      <div class="m-0">
        <ng-container *notInPopulation="[populations.Free]">
          <h5 class="mt-4 mb-2 title-partner">{{ 'partner.title' | translate }}</h5>
          <p
            class="m-0 font-size-14 partner-description"
            [innerHTML]="'partner.page.info.text' | translate | markdownTranslate"
          ></p>
        </ng-container>
        <ng-container *inPopulation="[populations.Free]">
          <h5 class="mt-4 mb-2 title-partner">{{ 'partner.free.title' | translate }}</h5>
          <p
            class="m-0 font-size-14 partner-description"
            [innerHTML]="'partner.page.free.info.text' | translate | markdownTranslate"
          ></p>
        </ng-container>
        <div class="m-0 flex-nowrap pe-0 ps-0">
          <div class="d-flex align-items-center flex-lg-row mb-4 mt-4">
            <ng-container *inPopulation="populations.Free">
              <button
              *ifScreenSize="screenType.Desktop"
              class="
                partner-button
                border-0
                text-center text-white
                bg-pro
                pt-0
                d-flex
                align-items-center
                justify-content-center
                me-lg-4
                mb-3 mb-lg-0
              "
              (click)="openAcceptForm()"
              aria-label="Add new partner" 
              [ngClass]="{
                'greyed-out': currentPartnerCount >= 1
              }"
            >
              <span class="material-icons me-2"> add_task </span>
              <label class="cursor-pointer mb-0">{{'button.accept.partner' | translate}}</label>
            </button>
            <button
              *ifScreenSize="screenType.Mobile"
              class="
                mb-partner-button
                border-0
                text-center text-white
                bg-pro
                pt-0
                d-flex
                align-items-center
                justify-content-center
                me-lg-4
                mb-3 mb-lg-0
              "
              [ngClass]="{
                'greyed-out': currentPartnerCount >= 1
              }"
              (click)="openAcceptForm()"
              aria-label="Add new partner"
            >
              <span class="material-icons me-2"> person_add_alt_1 </span>
              <label class="cursor-pointer mb-0">{{'button.accept.partner' | translate}}</label>
            </button>
            </ng-container>
            <ng-container *notInPopulation="populations.Free">
              <button
                *ifScreenSize="screenType.Desktop"
                class="
                  partner-button
                  border-0
                  text-center text-white
                  bg-primary
                  pt-0
                  d-flex
                  align-items-center
                  justify-content-center
                  me-lg-4
                  mb-3 mb-lg-0
                "
                (click)="openAddForm()"
                aria-label="Add new partner"
                [ngClass]="{
                  active: !showAcceptForm,
                  'not-active': showAcceptForm,
                  'greyed-out': currentPartnerCount >= partnerLimit
                }"
              >
                <span class="material-icons me-2"> person_add_alt_1 </span>
                <label class="cursor-pointer mb-0">Add New Partner</label>
              </button>
              <button
                *ifScreenSize="screenType.Mobile"
                class="
                  mb-partner-button
                  border-0
                  text-center text-white
                  bg-primary
                  pt-0
                  d-flex
                  align-items-center
                  justify-content-center
                  me-lg-4
                  mb-3 mb-lg-0
                "
                (click)="openAddForm()"
                aria-label="Add new partner"
                [ngClass]="{
                  active: !showAcceptForm,
                  'not-active': showAcceptForm,
                  'greyed-out': currentPartnerCount >= partnerLimit
                }"
              >
                <span class="material-icons me-2"> person_add_alt_1 </span>
                <label class="cursor-pointer mb-0">{{ 'nav.add.partner' | translate: { fgbdefault: 'Add New Partner' } }}</label>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showAddForm || showAcceptForm">
        <hr class="m-2" />
        <div class="card-body">
          <fgb-partner-add-form (close)="openAddForm()" *ngIf="showAddForm"></fgb-partner-add-form>
          <fgb-partner-accept-form (close)="openAcceptForm()" *ngIf="showAcceptForm"></fgb-partner-accept-form>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="currentPartnerCount > 0; else partnerPlaceholder">
    <fgb-partner-list></fgb-partner-list>
  </ng-container>
  <ng-template #partnerPlaceholder>
    <h5 class="mt-4 mb-3 title-partner" *inPopulation="[populations.Default]">
      {{ 'no.partner.placeholder.title' | translate }}
    </h5>
    <h5 class="mt-4 mb-2 title-partner" *notInPopulation="[populations.Default]">{{ 'partner.list.partner.title' | translate }}</h5>
    <div class="card border card-primary my-2 bg-white font-size-14">
      <div class="card-body pt-3">
        <div class="row text-start details-header mx-0">
          <div class="col-10 col-lg-3 d-lg-none d-block">
            <label class="fw-bold">{{ 'partner.details' | translate }}</label>
          </div>
          <div class="col-4 d-lg-block d-none ps-0">
            <label class="fw-bold">{{ 'partner.fullname' | translate }}</label>
          </div>
          <div class="col-5 d-lg-block d-none">
            <label class="fw-bold">{{ 'partner.email' | translate }}</label>
          </div>
          <div class="col-2 d-lg-block d-none ps-0">
            <label class="fw-bold">{{ 'partner.status' | translate }}</label>
          </div>
          <div class="col-1 d-lg-block d-none" *notInPopulation="[populations.Free]">
            <label class="fw-bold">{{ 'partner.action' | translate }}</label>
          </div>
          <div class="col-3 d-lg-block d-none"></div>
        </div>
        <div class="text-info text-center font-size-12 mt-3 font-sans-bold d-lg-block d-none">{{ 'no.partner.placeholder.description' | translate }}</div>
        <div class="text-info text-center font-size-12 mt-3 d-block d-lg-none">{{ 'no.partner.placeholder.mobile.description' | translate }}</div>
      </div>
    </div>
  </ng-template>
</div>
