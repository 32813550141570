<div class="banner d-none d-lg-flex" role="banner">
  <h1 class="text-white">{{'ecash.title.transfer' | translate}}</h1>
</div>

<div class="container my-3 d-lg-none d-block">
  <fgb-barcode></fgb-barcode>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div class="font-size-25 font-heading">
    {{ 'ecash.title.transfer' | translate }}
  </div>
  <div class="font-size-14">
    {{ 'ecash.description' | translate }} 
    <span class="text-success font-sans-bold">
      {{ 'ecash.description.send' | translate }}
    </span>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="arena-cash-box">
        <fgb-transfer-arena-cash></fgb-transfer-arena-cash>
      </div>
    </div>
  </div>
</div>
