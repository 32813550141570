import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProcessStatus, ScorePredictorStatus, Survey, SurveyQuery, SurveyService } from '@fgb/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { map, take, tap } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

import * as _moment from 'moment';
import { firstValueFrom } from 'rxjs';
const moment = _moment;

@Component({
  selector: 'fgb-score-predictor-list',
  templateUrl: './score-predictor-list.component.html',
  styleUrls: ['./score-predictor-list.component.scss'],
})
export class ScorePredictorListComponent implements OnInit {
  scorePredictors: Survey[] = [];
  @Input() displayTitle: boolean = false;
  @ViewChild('scorePredictorCarousel') scorePredictorCarousel: ElementRef;

  constructor(
    private surveyQuery: SurveyQuery,
    private surveyService: SurveyService
  ) {}

  scorePredictorOptions: OwlOptions = {
    autoWidth: true,
    pullDrag: false,
    dots: true,
    loop: false,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    responsive: {
      0: { items: 1.1, autoWidth: true },
      600: { items: 1.3 },
      740: { items: 2 },
    },
  };

  ngOnInit(): void {
    this.fetchScorePredictors();
  }

  async fetchScorePredictors(): Promise<void> {
    await firstValueFrom(this.surveyService.fetchAllSurveys(true, true, false, true));

    this.surveyQuery
      .selectScorePredictors()
      .pipe(
        take(1),
        map((survey) => this.reorderPredictors(survey)),
        tap((survey) => this.scorePredictors.push(...survey))
      )
      .subscribe();
  }

  removeFromCarousselNoRefresh(itemId: number) {
    const index = this.scorePredictors.findIndex((item) => item.SurveyId === itemId);
    this.scorePredictors.splice(index, 1);
  }

  reorderPredictors(surveys: Survey[]): Survey[] {
    const unAnsweredSurveys = surveys
      .filter((item) => this.isUnansweredSurvey(item))
      .sort((a, b) => this.sortSurveysByEndDate(a, b));

    const answeredSurveys = surveys.filter((item) => item.HasBeenAnsweredByUser && item.ProcessStatus === ProcessStatus.Ready);

    const editableSurveys = answeredSurveys
      .filter((item) => this.isEditableSurvey(item))
      .sort((a, b) => this.sortSurveysByEndDate(a, b));

    const nonEditableSurveys = answeredSurveys
      .filter((item) => this.isNonEditableSurvey(item))
      .sort((a, b) => this.sortSurveysByEndDate(a, b));

    const comingSoonSurveys = surveys
      .filter((item) => this.isComingSoonSurvey(item))
      .sort((a, b) => this.sortSurveysByEndDate(a, b));

    const processedAnsweredSurveys = surveys.filter(
      (item) => item.ProcessStatus === ProcessStatus.Processed && item.HasBeenAnsweredByUser
    );

    const orderedPredictors: Survey[] = [
      ...unAnsweredSurveys,
      ...editableSurveys,
      ...nonEditableSurveys,
      ...comingSoonSurveys,
      ...processedAnsweredSurveys,
    ];

    return orderedPredictors;
  }

  private isUnansweredSurvey(survey: Survey): boolean {
    const isOpenStatus = this.surveyService.scorePredictorStatus(survey) === ScorePredictorStatus.EntryOpen;
    const isNotProcessed = survey.ProcessStatus !== ProcessStatus.Processed;
    return !survey.HasBeenAnsweredByUser && isNotProcessed && isOpenStatus;
  }

  private isEditableSurvey(survey: Survey): boolean {
    const endDate = survey.SeparateEntryWindow ? moment.utc(survey.EntryEndDate).local() : moment.utc(survey.EndDate).local();
    return Math.floor(endDate.diff(moment(), 'seconds')) > 0;
  }

  private isNonEditableSurvey(survey: Survey): boolean {
    const endDate = survey.SeparateEntryWindow ? moment.utc(survey.EntryEndDate).local() : moment.utc(survey.EndDate).local();
    return Math.floor(endDate.diff(moment(), 'seconds')) <= 0;
  }

  private isComingSoonSurvey(survey: Survey): boolean {
    const status = this.surveyService.scorePredictorStatus(survey);
    return !survey.HasBeenAnsweredByUser && status === ScorePredictorStatus.ComingSoon;
  }

  private sortSurveysByEndDate(a: Survey, b: Survey): any {
    const endDateA = a.SeparateEntryWindow ? a.EntryEndDate : a.EndDate;
    const endDateB = b.SeparateEntryWindow ? b.EntryEndDate : b.EndDate;

    const dateDiffA = Math.floor(moment(endDateA).diff(moment.utc(), 'seconds'));
    const dateDiffB = Math.floor(moment(endDateB).diff(moment.utc(), 'seconds'));
    return dateDiffA - dateDiffB;
  }
}
