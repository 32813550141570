<div *ngIf="member$ | async as member">
  <form [formGroup]="detailsForm">
    <div class="">
      <label class="mb-0">Date of Birth</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">date_range</span>
        <span class="ms-3 text-info w-75">
          <input
            type="text"
            class="edit-input p-0"
            formControlName="dateOfBirth"
            ngbDatepicker
            [minDate]="{ year: 1930, month: 1, day: 1 }"
            [maxDate]="{ year: maxYear, month: currentMonth, day: currentDate }"
            [startDate]="{ year: maxYear, month: currentMonth, day: currentDate }"
            #dobDatePicker="ngbDatepicker"
            (click)="dobDatePicker.toggle()"
          />
        </span>
      </div>
    </div>
    <div>
      <label id="email-label" class="mb-0 personal-info">Email</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">email</span>
        <span class="ms-3 text-info w-75">
          <div>
            <span *ngIf="notInAnyEditMode()">{{ member.EmailAddress1 }}</span>
            <input
              aria-labelledby="email-label"
              class="edit-input p-0"
              *ngIf="anyEditMode()"
              type="text"
              formControlName="emailAddress"
            />
          </div>
        </span>
      </div>
    </div>
    <div>
      <label class="mb-0 personal-info">Home Address</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons d-inline-block" aria-hidden="true">location_on</span>
        <span class="ms-3 d-inline-block text-info w-75">
          <div>
            <span *ngIf="notInAnyEditMode()">{{ member.AddressName }}</span>
            <input
              attr.aria-label="{{ 'address.name.label' | translate }}"
              class="edit-input p-0"
              *ngIf="anyEditMode()"
              type="text"
              formControlName="addressName"
            />
          </div>
          <div>
            <span *ngIf="notInAnyEditMode()">{{ member.Street }}</span>
            <input
              attr.aria-label="{{ 'address.street.label' | translate }}"
              class="edit-input p-0"
              *ngIf="anyEditMode()"
              type="text"
              formControlName="street"
            />
          </div>
          <div>
            <span *ngIf="notInAnyEditMode()">{{ member.Town }}</span>
            <input
              attr.aria-label="{{ 'address.city.label' | translate }}"
              class="edit-input p-0"
              *ngIf="anyEditMode()"
              type="text"
              formControlName="town"
            />
          </div>
          <div>
            <span *ngIf="notInAnyEditMode()">{{ member.Country }}</span>
            <input
              attr.aria-label="{{ 'address.country.label' | translate }}"
              class="edit-input p-0"
              *ngIf="anyEditMode()"
              type="text"
              formControlName="country"
            />
          </div>
          <div>
            <span *ngIf="notInAnyEditMode()">{{ member.PostCode }}</span>
            <input
              attr.aria-label="{{ 'address.postcode.label' | translate }}"
              class="edit-input p-0"
              *ngIf="anyEditMode()"
              type="text"
              formControlName="postcode"
            />
          </div>
        </span>
      </div>
    </div>
    <ng-container *ngIf="isLoading">
      <fgbcl-loading></fgbcl-loading>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <button
        type="button"
        class="edit-button fw-bold d-block mx-auto border-0 rounded text-white bg-primary"
        *ngIf="notInAnyEditMode()"
        (click)="edit()"
      >
        Edit
      </button>
      <button
        #autoEditConfirm
        type="button"
        class="save-button fw-bold d-block mx-auto border-0 rounded text-white bg-success"
        *ngIf="autoEditMode"
        [ngClass]="disableIfFormIncomplete()"
        (click)="save()"
      >
        Confirm
      </button>
      <div class="d-flex justify-content-between">
        <button
          type="button"
          class="cancel-button fw-bold d-inline-block border-0 rounded text-white bg-danger"
          *ngIf="isInEditMode"
          (click)="stopEditing()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="save-button fw-bold d-inline-block border-0 rounded text-white bg-success"
          *ngIf="isInEditMode"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </ng-container>
  </form>
</div>
