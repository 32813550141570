import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  BalanceQuery,
  BalanceService,
  config,
  ErrorStateService,
  MemberQuery,
  Partner,
  PartnerQuery,
  PointsTransferService,
  RenewalResponse,
} from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { firstValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-partner-bonus-transfer',
  templateUrl: './partner-bonus-transfer.component.html',
  styleUrls: ['./partner-bonus-transfer.component.scss'],
})
export class PartnerBonusTransferComponent implements OnInit {
  transferValue = 1;
  targetMemberId: string = '';
  partners$: Observable<Partner[]>;
  customOptions: OwlOptions = {
    margin: OwlCarouselSettings.Margin,
    autoHeight: true,
    autoWidth: true,
    mouseDrag: false,
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 2 },
      900: { items: 3 },
    },
  };
  selectedPartner: Partner | undefined;
  transferStatus: boolean = false;
  @ViewChild('successTransfer', { static: true }) successModalRef: ElementRef;
  @ViewChild('failedTransfer', { static: true }) failedModalRef: ElementRef;
  currentBalance: number;
  isLoading: boolean = false;
  minTransfer = 1;

  constructor(
    private memberQuery: MemberQuery,
    private pointTransferService: PointsTransferService,
    private partnerQuery: PartnerQuery,
    private balanceQuery: BalanceQuery,
    private modalService: NgbModal,
    private errorService: ErrorStateService,
    private balanceService: BalanceService
  ) {}

  ngOnInit() {
    this.balanceQuery
      .selectPurse(config.purseConfig.renewalGift)
      .subscribe((bal) => {
        this.currentBalance = bal ?? 0;
      })
      .unsubscribe();

    this.defaultSelectOnlyPartner();
  }

  // select partner to for bonus transfer
  selectPartner(selectedPartner: Partner) {
    this.selectedPartner = selectedPartner;
    this.targetMemberId = selectedPartner.MemberId;
  }

  // call to transfer bonus credit to given Member ID
  transferBonusCredit(bonusAmount: number) {
    this.isLoading = true;
    let sourceMember = this.memberQuery.getUserContext();
    let amountInPence = Math.round(bonusAmount * 100);
    firstValueFrom(this.pointTransferService.renewalBonusTransfer(sourceMember.memberId, this.targetMemberId, amountInPence))
      .then((response) => {
        let renewalResponse = response as RenewalResponse;
        if (!!renewalResponse.ErrorNumber) {
          this.errorService.addError(renewalResponse.ErrorDescription);
          this.transferStatus = false;
          this.openTransferStatusModal();
        } else if (!!renewalResponse.Tickets.filter((ticket) => !!ticket.ErrorNumber).length) {
          this.errorService.addError(renewalResponse.Tickets.filter((ticket) => !!ticket.ErrorNumber)[0].ErrorDesc);
          this.transferStatus = false;
          this.openTransferStatusModal();
        } else {
          this.transferStatus = true;
          this.openTransferStatusModal();
          this.balanceService.debitBalance(config.purseConfig.renewalGift, bonusAmount);
          this.balanceService.debitBalance(config.purseConfig.combined, bonusAmount);
        }
        this.isLoading = false;
        this.selectedPartner = undefined;
        this.transferValue = 1;
      })
      .catch((err) => {
        this.isLoading = false;
        this.transferStatus = false;
        this.openTransferStatusModal();
      });
  }

  openTransferStatusModal() {
    if (this.transferStatus) {
      this.modalService.open(this.successModalRef, { centered: true });
    } else {
      this.modalService.open(this.failedModalRef, { centered: true });
    }
  }

  // default selection to one partner if length of partner is equal to 1
  defaultSelectOnlyPartner() {
    this.partners$ = this.partnerQuery.selectAll().pipe(
      tap((partners) => {
        if (partners.length == 1) {
          this.selectedPartner = partners[0];
          this.targetMemberId = this.selectedPartner.MemberId;
        }
      })
    );
  }
}
