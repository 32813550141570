<nav class="navbar bg-primary navbar-dark navbar-expand-lg d-none d-lg-flex shadow">
  <a
    class="navbar-brand py-0"
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    attr.aria-label="{{ 'nav.home.page.screenreader' | translate: { fgbdefault: 'Move to home page' } }}"
  >
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <img
      alt=""
      class="ms-2"
      aria-labelledby="homeLink"
      width="43"
      src="assets/images/MyTwins_PrimaryLogo_Light.png"
      draggable="false"
    />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav me-auto" #greedyNav>
        <ng-container *inPopulation="[populations.Suite, populations.Pts]">
          <li class="nav-item active">
            <a
              class="nav-link mx-1 text-white"
              [routerLink]="['/account']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.account.screenreader' | translate: { fgbdefault: 'Move to account page' } }}"
              >{{ 'nav.account' | translate }}</a
            >
          </li>
          <li class="nav-item active">
            <a
              *notInPopulation="[populations.Pts]"
              class="nav-link mx-1 text-white"
              [routerLink]="['/partners']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.partner.screenreader' | translate: { fgbdefault: 'Move to partner page' } }}"
              >{{ 'nav.partner' | translate }}</a
            >
          </li>
          <li class="nav-item active">
            <a
              class="nav-link mx-1 text-white"
              [routerLink]="['/faq']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.faq.screenreader' | translate: { fgbdefault: 'Move to faq page' } }}"
              >{{ 'nav.faq' | translate }}</a
            >
          </li>
          <li class="nav-item active">
            <a
              class="nav-link mx-1 text-white"
              [routerLink]="['/contact']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.contact.us.screenreader' | translate: { fgbdefault: 'Move to contact us page' } }}"
              >{{ 'nav.contact.us' | translate }}</a
            >
          </li>
          <li class="nav-item active">
            <a
              class="nav-link mx-1 text-white"
              [routerLink]="['/terms']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.terms.screenreader' | translate: { fgbdefault: 'Move to terms page' } }}"
              >{{ 'nav.terms' | translate }}</a
            >
          </li>
        </ng-container>
        <li class="nav-item active" *inPopulation="[populations.Default, populations.Partner, populations.Free]">
          <a
            class="nav-link mx-1 text-white"
            [routerLink]="['/rewards']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.rewards.screenreader' | translate: { fgbdefault: 'Move to rewards page' } }}"
            >{{ 'nav.rewards' | translate }}</a
          >
        </li>
        <li class="nav-item active" *inPopulation="[populations.Default, populations.Partner, populations.Free]">
          <a
            class="nav-link mx-1 text-white"
            [routerLink]="['/challenges']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.challenges.screenreader' | translate: { fgbdefault: 'Move to challenges page' } }}"
            >{{ 'nav.challenges' | translate }}</a
          >
        </li>
        <li class="nav-item active" *inPopulation="[populations.Default, populations.Partner, populations.Free]">
          <a
            class="nav-link mx-1 text-white"
            [routerLink]="['/rewards/wallet']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.wallet.screenreader' | translate: { fgbdefault: 'Move to wallet page' } }}"
            >{{ 'nav.wallet' | translate }}</a
          >
        </li>
        <li class="nav-item active" *inPopulation="[populations.Default, populations.Partner, populations.Free]">
          <a
            class="nav-link mx-1 text-white"
            [routerLink]="['/earn']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.earns.screenreader' | translate: { fgbdefault: 'Move to earns page' } }}"
            >{{ 'nav.earns' | translate }}</a
          >
        </li>
        <li class="nav-item active" *inPopulation="[populations.Default, populations.Partner, populations.Free]">
          <a
            class="nav-link mx-1 text-white"
            [routerLink]="['/tier']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.tier.screenreader' | translate: { fgbdefault: 'Move to tier page' } }}"
            >{{ 'nav.tier' | translate }}</a
          >
        </li>
        <!--More-->
        <li class="nav-item active more-dropdown" *inPopulation="[populations.Default, populations.Partner, populations.Free]">
          <div ngbDropdown role="group">
            <a class="nav-link mx-1 text-white" ngbDropdownToggle>
              <span class="me-1">{{ 'nav.help' | translate }}</span>
            </a>
            <div ngbDropdownMenu class="py-2 mt-2 me-4 w-100 rounded-0 help-dropdown" #greedyDropdown>
              <a ngbDropdownItem [routerLink]="['/faq']" class="ps-2 mb-1 d-block font fw-normal font-size-14 more-links">
                {{ 'nav.faq' | translate }}
              </a>
              <a ngbDropdownItem [routerLink]="['/contact']" class="ps-2 mb-1 d-block fw-normal font-size-14 more-links">
                {{ 'nav.contact.us' | translate }}
              </a>
              <a ngbDropdownItem [routerLink]="['/terms']" class="ps-2 mb-1 d-block fw-normal font-size-14 more-links">
                {{ 'nav.terms' | translate }}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Right Side Content -->
    <div class="d-flex align-items-center flex-nowrap my-2 my-lg-0">
      <div class="d-flex text-white align-items-center me-4" *notInPopulation="[populations.Suite, populations.Pts]">
        <span class="icon material-icons font-size-20 me-2">emoji_events</span>
        <fgb-points-summary></fgb-points-summary>
      </div>
      <!--eCash-->
      <ng-container *inPopulation="[populations.Default, populations.Partner, populations.Free]">
        <div *ngIf="combinedCard$" class="d-flex align-items-center pe-4 text-white border-end">
          <mat-icon class="filled-icon material-symbols-outlined font-size-20">credit_card</mat-icon>
          <span class="ps-1 font-size-14 fw-bold"> {{ combinedCard$ | async | FortressCurrencyPipe }}</span>
        </div>
      </ng-container>
      <!--New Dropdown (Needs Manage Tickets update before being looked at again)-->
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn d-flex text-white align-items-center mx-2 px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate: { fgbdefault: 'Click for more account menu' } }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-start text-white ps-3">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="text-center text-white dropdown-menu p-0 account-dropdown mt-2"
        >
          <div class="text-info">
            <a
              *inPopulation="[populations.Default, populations.Partner, populations.Free, populations.Suite, populations.Pts]"
              [routerLink]="['/account']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.account.screenreader' | translate: { fgbdefault: 'Move to account page' } }}"
              class="d-flex align-items-center account-btn text-black bg-white py-0 font-size-14 nav-item-fixed-height rounded-top border-bottom"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">person</span>
              <div class="dropdown-link text-start">
                <div class="mt-2 fw-bold">Account</div>
                <div class="nav-bar-little-text text-start text-info" *ngIf="card$ | async as memberCard">
                  {{ memberCard.ProductCode }}
                </div>
              </div>
            </a>
          </div>

          <div class="text-info">
            <a
              *inPopulation="[populations.NonRenewingPopulation]"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.account.screenreader' | translate: { fgbdefault: 'Move to account page' } }}"
              class="d-flex align-items-center account-btn text-black bg-white py-0 font-size-14 nav-item-fixed-height rounded-top border-bottom"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">person</span>
              <div class="dropdown-link text-start">
                <div class="mt-2 fw-bold">Account</div>
                <div class="nav-bar-little-text text-start text-info" *ngIf="card$ | async as memberCard">
                  {{ memberCard.ProductCode }}
                </div>
              </div>
            </a>
          </div>

          <ng-container *ngIf="cards$ | async as cards">
            <div *ngIf="cards.length > 1">
              <div class="text-secondary ms-3">
                <div class="d-flex align-items-center account-btn bg-white py-0 px-2 nav-item-fixed-height" ngbDropdownItem>
                  <div class="icon icon-user-male-pictures me-2 font-size-16 text-primary"></div>
                  <div class="dropdown-link text-start">
                    <div class="font-size-14 text-black fw-bold">Switch Account</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="text-white" *inPopulation="[populations.Default, populations.Partner, populations.Free]">
            <a
              [routerLink]="['/rewards/wallet']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.wallet.screenreader' | translate: { fgbdefault: 'Move to wallet page' } }}"
              class="d-flex align-items-center account-btn text-black bg-white py-0 nav-item-fixed-height border-bottom"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">account_balance_wallet</span>
              <div class="dropdown-link text-start">
                <div class="font-size-14 fw-bold">{{ 'rewards.nav.wallet' | translate }}</div>
              </div>
            </a>
          </div>

          <div *inPopulation="[populations.Default, populations.Partner, populations.Free, populations.Suite, populations.Pts]">
            <button
              class="logout-btn d-flex align-items-center bg-white nav-item-fixed-height rounded-bottom"
              (click)="logout()"
              ngbDropdownItem
              attr.aria-label="{{ 'nav.log.out.screenreader' | translate: { fgbdefault: 'Log out' } }}"
            >
              <span class="icon material-icons material-dropdown-icon me-2 text-danger" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link text-start">
                <div class="text-danger py-1 font-size-14 fw-bold" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate: { fgbdefault: 'Log out' } }}
                </div>
              </div>
            </button>
          </div>
          <div *inPopulation="[populations.NonRenewingPopulation]">
            <button
              class="logout-btn d-flex align-items-center bg-white px-2 nav-item-fixed-height rounded-bottom"
              ngbDropdownItem
              attr.aria-label="{{ 'nav.log.out.screenreader' | translate: { fgbdefault: 'Log out' } }}"
            >
              <span class="icon material-icons material-dropdown-icon me-2 text-danger" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link text-start">
                <div class="text-danger py-1 font-size-14 fw-bold" ngbDropdownItem>
                  {{ 'nav.log.out' | translate: { fgbdefault: 'Log out' } }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
