<nav class="nav nav-secondary account-details-nav">
  <!--Account-->
  <a
    class="nav-item"
    [routerLink]="['/account']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.account.screenreader' | translate: { fgbdefault: 'Move to Account page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">person</div>
    <label>{{ 'account.nav.profile' | translate }}</label>
  </a>
  <!--eCash Transaction-->
  <a
    class="nav-item"
    [routerLink]="['/activity']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.activity.screenreader' | translate: { fgbdefault: 'Move to Transaction History page' } }}"
    *notInPopulation="[populations.Pts, populations.Suite]"
  >
    <div class="icon material-icons" aria-hidden="true">history</div>
    <label>{{ 'account.nav.activity' | translate }}</label>
  </a>

  <!--Partners-->
  <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
    <a
      class="nav-item"
      [routerLink]="['/partners']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      attr.aria-label="{{ 'account.nav.partners.screenreader' | translate: { fgbdefault: 'Move to partners page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">people</div>
      <label>{{ 'account.nav.partners' | translate }}</label>
    </a></ng-container
  >
</nav>
