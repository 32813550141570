<div class="card">
  <div class="mt-5" *ngIf="loadingPaymentForm && currentStep != 2">
    <fgbcl-loading></fgbcl-loading>
  </div>
  <div class="card-body" *ngIf="portalProducts">
    <div class="row">
      <div class="col-lg-12">
        <!--Membership Options-->
        <ng-container *ngIf="currentStep == 0">
          <div class="row h-100">
            <div class="col-lg-5 d-none d-lg-block login-banner"></div>
            <div class="col-lg-7 personal-details-panel h-100">
              <div class="px-3 h-100 terms-panel pb-5">
                <div class="d-flex justify-content-center align-items-center mb-4 d-lg-none">
                  <img alt="" src="assets/images/KN-logo.png" class="kid-logo" />
                </div>
                <h4 class="text-lg-start text-center text-primary mb-4">{{ 'kids.membership.upgrade.title' | translate }}</h4>
                <p class="font-size-14">{{ 'kids.membership.upgrade.description' | translate }}</p>

                <fgb-membership-options-carousel></fgb-membership-options-carousel>

                <div class="child-names-section mb-5">
                  <hr class="my-1 bg-muted" />
                  <div *ngFor="let child of childUpgrades; let i = index">
                    <div class="py-2">
                      <h5 class="text-primary font-size-16">Child {{ i + 1 }}</h5>
                      <div class="d-flex flex-column flex-lg-row w-100 justify-content-between">
                        <div class="flex-column">
                          <label class="mb-0 child-name">{{ child.FirstName + ' ' + child.LastName }}</label>
                          <p class="mb-0 font-size-12">{{ child.DateOfBirth | date }}</p>
                        </div>
                        <div class="flex-column">
                          <div class="form-group me-2">
                            <select
                              class="form-control bg-muted mt-2 p-1 border-0 font-size-12"
                              [value]="child.Product.ProductId"
                              (change)="childProductChange(child, $event.target.value)"
                            >
                              <option value="0">Do Not Upgrade</option>
                              <ng-container *ngFor="let product of portalProducts">
                                <option
                                  [value]="product.PortalProduct.ProductId"
                                  [selected]="child.Product.ProductId == product.PortalProduct.ProductId"
                                >
                                  {{ product.PortalProduct.DisplayName }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="my-1 bg-muted" />
                  </div>
                </div>
              </div>
              <div class="button-container px-3 pb-3 pb-lg-0 d-flex justify-content-between">
                <!-- Previous Button -->
                <button (click)="CloseModal()" class="btn btn-primary nav-button fw-bold mb-0">
                  Back
                  <ng-template #noPreviousStep>Back</ng-template>
                </button>

                <!-- Next Button -->
                <button
                  (click)="showNext(1)"
                  class="btn btn-secondary nav-button fw-bold mb-0"
                  [disabled]="noMembershipsSelected"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <!--Review Payment-->
        <ng-container *ngIf="currentStep == 1">
          <div class="p-1 review-panel py-1 px-3 px-lg-3">
            <div class="d-flex justify-content-center align-items-center mb-4 mt-3 d-lg-none">
              <img alt="" src="assets/images/KN-logo.png" class="kid-logo" />
            </div>
            <h2 class="text-lg-start text-center text-primary my-3">{{ 'kids.membership.upgrade.review.label' | translate }}</h2>
            <div class="font-size-14">{{ 'kids.membership.upgrade.review.description' | translate }}</div>
            <div class="row">
              <div class="col-lg-7">
                <hr class="border-info w-100 hr-items mt-1" />
                <div class="font-size-12">
                  <div class="text-black my-2">
                    <div *ngFor="let child of childUpgrades">
                      <ng-container *ngIf="child.Product.ProductId > 0">
                        <div class="row my-1">
                          <div class="col-12">
                            <span class="fw-bold">{{ child.FirstName }} {{ child.LastName }}</span>
                            -
                            {{ child.Product.DisplayName }}
                          </div>
                          <div class="col-12">
                            <span class="fw-bold">{{ 'kids.membership.upgrade.product.cost.label' | translate }}</span>
                            {{ child.Product.MerchantPrice / 100 | FortressCurrencyPipe }}
                          </div>
                        </div>
                        <hr class="border-info w-100 hr-items" />
                      </ng-container>
                    </div>
                  </div>
                </div>
                <button class="btn text-primary fw-bold d-none d-lg-block ps-0" (click)="showPrevious(0)">
                  <span class="font-size-14">&#60; Back</span>
                </button>
              </div>

              <!-- Right Side -->
              <div [ngClass]="{ 'd-none': editingBillingAddress || editingShippingAddress }" class="col-lg-5">
                <div class="position-relative rounded shadow p-3 mb-2">
                  <div class="top-right">
                    <a class="cursor-pointer" (click)="editBillingAddress()"
                      ><span class="text-black icon icon-pencil2"></span
                    ></a>
                  </div>
                  <h6 class="mb-3 text-primary address-title">Billing Address</h6>
                  <div class="mb-0 text-info font-size-14">
                    {{ billingAddress.Street }}, {{ billingAddress.Town }}, {{ billingAddress.County }},
                    {{ billingAddress.Postcode }}, {{ billingAddress.Country }}
                  </div>
                </div>
                <div class="position-relative rounded shadow p-3">
                  <div class="top-right">
                    <a class="cursor-pointer" (click)="editShippingAddress()"
                      ><span class="text-black icon icon-pencil2"></span
                    ></a>
                  </div>
                  <h6 class="mb-3 text-primary address-title">Shipping Address</h6>
                  <div class="mb-0 text-info font-size-14">
                    {{ shippingAddress.Street }}, {{ shippingAddress.Town }}, {{ shippingAddress.County }},
                    {{ shippingAddress.Postcode }}, {{ shippingAddress.Country }}
                  </div>
                </div>

                <!-- Giftcode input -->
                <div class="input-group rounded mt-4">
                  <input
                    type="text"
                    class="form-control border-info bg-muted gift-code-input font-size-14"
                    [(ngModel)]="giftCodeInput"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-info text-white form-control heading-text-one gift-code-input font-size-14 fw-bold border-info"
                      type="button"
                      (click)="applyGiftCode()"
                      [disabled]="disableApplyGiftCodesButton"
                    >
                      {{ 'kids.membership.upgrade.gift.code' | translate }}
                    </button>
                  </div>
                </div>

                <fgbcl-errors></fgbcl-errors>

                <div class="rounded shadow p-3 my-2">
                  <h6 class="mb-3 text-primary address-title">{{ 'kids.membership.upgrade.summary.label' | translate }}</h6>
                  <div class="row font-size-12 mb-2">
                    <div class="col-9 fw-bold">Membership total:</div>
                    <div class="col-3 text-end">{{ totalAmount / 100 | FortressCurrencyPipe }}</div>
                  </div>
                  <div class="row font-size-12 mb-2">
                    <div class="col-9 fw-bold">Shipping:</div>
                    <div class="col-3 text-end">{{ totalShipping / 100 | FortressCurrencyPipe }}</div>
                  </div>
                  <ng-container *ngIf="totalTax > 0">
                    <div class="row font-size-12 mb-2">
                      <div class="col-9 fw-bold">Tax:</div>
                      <div class="col-3 text-end">{{ totalTax / 100 | FortressCurrencyPipe }}</div>
                    </div>
                  </ng-container>
                  <div class="row font-size-12 mb-2" *ngIf="appliedGiftCodes && appliedGiftCodes.length > 0">
                    <div class="col-12 fw-bold">Discount Code(s):</div>
                    <ng-container *ngFor="let giftCode of appliedGiftCodes">
                      <div class="col-9"><button (click)="removeGiftCode(giftCode.Code)">X</button> {{ giftCode.Code }}</div>
                      <div class="col-3 text-end">
                        <span *ngIf="giftCode.EntitlementValueType == 0"
                          >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
                        >
                        <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
                        <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row fw-bold font-size-14 mt-2">
                    <div class="col-8">{{ 'kids.membership.upgrade.payment.details.total' | translate }}</div>
                    <div class="col-4 text-end">
                      {{ totalBasketAmount / 100 | FortressCurrencyPipe }}
                    </div>
                  </div>
                </div>
                <!--Pay now button-->
                <button
                  class="btn btn-success fw-bold mt-4 w-100 font-size-14 d-lg-block d-none"
                  (click)="showModalPayment(paymentDetails)"
                >
                  Proceed to Payment
                </button>
              </div>

              <!-- EDIT BILLING ADDRESS FORM -->
              <ng-container *ngIf="editingBillingAddress">
                <div class="col-lg-5">
                  <div class="position-relative rounded shadow p-3">
                    <div class="top-right text-primary fw-bold cursor-pointer font-size-12">
                      <a (click)="clearAddressForm(billingAddressForm)">Clear Form</a>
                    </div>
                    <h6 class="mb-3 text-primary address-title">Billing Address</h6>
                    <form [formGroup]="billingAddressForm" class="font-size-12">
                      <div class="form-row">
                        <div class="form-group col-6">
                          <label for="FirstName">
                            First Name
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            id="FirstName"
                            class="form-control"
                            type="text"
                            formControlName="FirstName"
                            [ngClass]="{
                              'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.FirstName.errors
                            }"
                            required
                          />
                          <div class="invalid-feedback">Please enter your first name.</div>
                        </div>

                        <div class="form-group col-6">
                          <label for="Surname">
                            Last Name
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            id="Surname"
                            class="form-control"
                            type="text"
                            formControlName="Surname"
                            [ngClass]="{
                              'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Surname.errors
                            }"
                            required
                          />
                          <div class="invalid-feedback">Please enter your last name.</div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="Street">
                          Street
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Street"
                          class="form-control"
                          name="Street"
                          type="text"
                          formControlName="Street"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Street.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid street.</div>
                      </div>

                      <div class="form-group">
                        <label for="Town">
                          City
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Town"
                          class="form-control"
                          name="Town"
                          type="text"
                          formControlName="Town"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Town.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid city</div>
                      </div>

                      <div class="form-group">
                        <label for="County">
                          State
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="County"
                          class="form-control"
                          name="County"
                          type="text"
                          formControlName="County"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.County.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a state.</div>
                      </div>

                      <div class="form-group">
                        <label for="Postcode">
                          Zip Code
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Postcode"
                          class="form-control"
                          name="Postcode"
                          type="text"
                          formControlName="Postcode"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Postcode.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid zip code.</div>
                      </div>

                      <div class="form-group">
                        <label for="Country">
                          Country
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Country"
                          class="form-control"
                          name="Country"
                          type="text"
                          formControlName="Country"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Country.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid country.</div>
                      </div>
                    </form>
                    <div class="d-flex justify-content-between mt-3 font-size-14">
                      <button class="btn btn-danger fw-bold btn-edit" (click)="cancelAndRebuildForm('billing')">
                        Cancel
                      </button>
                      <button class="btn btn-success fw-bold btn-edit" (click)="updateBillingAddress()">Save</button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="editingShippingAddress">
                <div class="col-lg-5">
                  <div class="position-relative rounded shadow p-3">
                    <div class="top-right text-primary fw-bold cursor-pointer font-size-12">
                      <a (click)="clearAddressForm(shippingAddressForm)">Clear Form</a>
                    </div>
                    <h6 class="mb-3 text-primary address-title">Shipping Address</h6>
                    <form [formGroup]="shippingAddressForm" class="font-size-12">
                      <div class="form-row">
                        <div class="form-group col-6">
                          <label for="FirstName">
                            First Name
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            id="FirstName"
                            class="form-control"
                            type="text"
                            formControlName="FirstName"
                            [ngClass]="{
                              'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.FirstName.errors
                            }"
                            required
                          />
                          <div class="invalid-feedback">Please enter your first name.</div>
                        </div>

                        <div class="form-group col-6">
                          <label for="Surname">
                            Last Name
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            id="Surname"
                            class="form-control"
                            type="text"
                            formControlName="Surname"
                            [ngClass]="{
                              'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Surname.errors
                            }"
                            required
                          />
                          <div class="invalid-feedback">Please enter your last name.</div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="Street">
                          Street
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Street"
                          class="form-control"
                          name="Street"
                          type="text"
                          formControlName="Street"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Street.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid street.</div>
                      </div>

                      <div class="form-group">
                        <label for="Town">
                          City
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Town"
                          class="form-control"
                          name="Town"
                          type="text"
                          formControlName="Town"
                          [ngClass]="{ 'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Town.errors }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid city.</div>
                      </div>

                      <div class="form-group">
                        <label for="County">
                          State
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="County"
                          class="form-control"
                          name="County"
                          type="text"
                          formControlName="County"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.County.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid state.</div>
                      </div>

                      <div class="form-group">
                        <label for="Postcode">
                          Zip Code
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Postcode"
                          class="form-control"
                          name="Postcode"
                          type="text"
                          formControlName="Postcode"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Postcode.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid zip code.</div>
                      </div>

                      <div class="form-group">
                        <label for="Country">
                          Country
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Country"
                          class="form-control"
                          name="Country"
                          type="text"
                          formControlName="Country"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Country.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid country.</div>
                      </div>
                    </form>
                    <div class="d-flex justify-content-between mt-3 font-size-14">
                      <button class="btn btn-danger fw-bold btn-edit" (click)="cancelAndRebuildForm('shipping')">
                        Cancel
                      </button>
                      <button class="btn btn-success fw-bold btn-edit" (click)="updateShippingAddress()">Save</button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="button-container px-3 mb-3 mb-lg-0 d-flex justify-content-between d-lg-none">
            <!-- Previous Button -->
            <button (click)="showPrevious(0)" class="btn btn-primary nav-button fw-bold mb-0">
              Back
              <ng-template #noPreviousStep>Back</ng-template>
            </button>

            <!-- Next Button -->
            <button (click)="showNext(2)" class="btn btn-success nav-button fw-bold mb-lg-0">Payment</button>
          </div>
        </ng-container>

        <!--Payment Process-->
        <ng-container *ngIf="currentStep == 2">
          <ng-container *ngTemplateOutlet="paymentDetails"></ng-container>
        </ng-container>

        <ng-container *ngIf="orderCompleted">
          <div class="d-flex flex-row h-100 completed-screen">
            <div class="col-lg-5 d-none d-lg-block login-banner"></div>
            <div class="col-lg-7 px-lg-5 px-3 d-flex flex-column justify-content-between">
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-center align-items-center my-2 d-lg-none">
                  <img alt="" src="assets/images/kidnationnewlogo.svg" class="kid-logo" />
                </div>
                <h2 class="text-lg-start text-center text-success my-lg-3 mb-4">{{ 'purchase.success.title' | translate }}</h2>
                <p class="font-size-14 mb-5">
                  {{ 'purchase.success.description' | translate }}
                  <span class="text-primary fw-bold">{{ 'purchase.success.description.email' | translate }}</span
                  ><strong>{{ 'purchase.success.description.number' | translate }}</strong>
                </p>
                <p class="font-size-14 text-start fw-bold mb-lg-3 mb-2">
                  {{ 'kids.membership.upgrade.summary.label' | translate }}
                </p>
                <hr class="border-info w-100 hr-items mt-1" />
                <div class="font-size-12">
                  <div class="text-black my-2">
                    <div *ngFor="let child of childUpgrades">
                      <ng-container *ngIf="child.Product.ProductId > 0">
                        <div class="row my-1">
                          <div class="col-12 mb-2">
                            <span class="fw-bold">{{ child.FirstName }} {{ child.LastName }}</span>
                            -
                            {{ child.Product.DisplayName }}
                          </div>
                          <div class="col-12">
                            <span class="fw-bold">{{ 'kids.membership.upgrade.product.cost.label' | translate }}</span>
                            {{ child.Product.MerchantPrice / 100 | FortressCurrencyPipe }}
                          </div>
                        </div>
                        <hr class="border-info w-100 hr-items" />
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-end font-size-14 mt-2">
                  <span class="fw-bold">{{ 'purchase.success.total.label' | translate }}</span
                  >{{ totalBasketAmount / 100 | FortressCurrencyPipe }}
                </div>
              </div>
              <div class="w-100 d-flex justify-content-center">
                <button class="btn btn-primary w-80 my-lg-4 my-3 font-size-14 fw-bold" (click)="CloseModal()">
                  {{ 'kids.membership.upgrade.button.completed' | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #paymentDetails>
  <div class="d-flex flex-column w-100 w-lg-50">
    <div class="d-flex flex-row bg-primary text-white align-items-center justify-content-between remove-outline p-2">
      <div class="d-flex flex-row align-items-center">
        <span class="icon material-icons me-2 font-size-24" aria-hidden="true">credit_card</span>
        <h5 class="text-start m-0">{{ 'kids.membership.upgrade.payment.details.title' | translate }}</h5>
      </div>
      <div type="button" class="border-0" (click)="showPrevious(1)">
        <span class="icon icon-cancel-circle font-size-20"></span>
      </div>
    </div>
    <div class="mx-3">
      <p class="text-center fw-bold my-3">
        {{ 'kids.membership.upgrade.payment.details.description' | translate }}
      </p>
      <!-- Summary -->
      <div class="my-4 m-lg-5">
        <div class="row text-black mt-2 mb-5">
          <div class="col-9">{{ 'kids.membership.upgrade.payment.details.total' | translate }}</div>
          <div class="col-3 fw-bold">{{ totalBasketAmount / 100 | FortressCurrencyPipe }}</div>
        </div>
        <!-- Payment Provider Form -->
        <div *ngIf="loadingPaymentForm">
          <fgbcl-loading></fgbcl-loading>
        </div>
        <div id="paymentDiv" class="mb-4"></div>
      </div>
    </div>
  </div>
</ng-template>
