import { Component, OnInit, Input } from '@angular/core';
import { MemberCard, MemberCardQuery, MemberDetails, Balance, MemberQuery, BalanceQuery, config } from '@fgb/core';
import { Observable } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';
import { TierLevels } from 'src/app/shared/utilities/tier-levels';
@Component({
  selector: 'fgb-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
  @Input() showSeating: boolean;
  memberCard$: Observable<MemberCard | undefined>;
  ecash$: Observable<number | undefined>;
  populations = Populations;
  memberDetails$: Observable<MemberDetails | undefined>;
  balance$: Observable<Balance>;
  member$: Observable<MemberDetails | undefined>;
  pointsBalance$: Observable<number | undefined>;
  combinedCard$: Observable<number | undefined>;
  tierName: string | undefined;
  tierLevels = TierLevels;
  constructor(private memberCardQuery: MemberCardQuery, private memberQuery: MemberQuery, private balanceQuery: BalanceQuery) { }
  ngOnInit() {
    this.tierName = this.memberQuery.getUserContext().tierName
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.ecash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
  }
}
