import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  PartnerQuery,
  PartnerPendingQuery,
  MemberDetails,
  MemberQuery,
  PartnerService,
  Partner,
  PartnerPending,
} from '@fgb/core';
import { takeUntil } from 'rxjs/operators';
import { PartnerAddFormComponent } from 'src/app/modules/user/components/partner-add-form/partner-add-form.component';
import { PartnerAcceptFormComponent } from 'src/app/modules/user/components/partner-accept-form/partner-accept-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Populations } from 'src/app/shared/utilities/populations';
@Component({
  selector: 'fgb-partner-page',
  templateUrl: './partner-page.component.html',
  styleUrls: ['./partner-page.component.scss'],
})
export class PartnerPageComponent implements OnInit, OnDestroy {
  populations = Populations;
  constructor(
    private partnerQuery: PartnerQuery,
    private pendingQuery: PartnerPendingQuery,
    public memberQuery: MemberQuery,
    private modalService: NgbModal,
    private partnerService: PartnerService
  ) {}
  showAddForm: boolean;
  screenType = ScreenType;
  showAcceptForm: boolean;
  partnersCount: number;
  memberDetails$: Observable<MemberDetails | undefined>;
  partnerLimit$: Observable<number>;
  partners: Partner[];
  pendingPartners: PartnerPending[];
  currentPartnerCount: number;

  private _destroyed$ = new Subject<void>();

  ngOnInit() {
    this.partnerLimit$ = this.partnerService.getPartnerLimit();

    combineLatest([this.partnerQuery.selectAll(), this.pendingQuery.selectAll()])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([partners, pendingPartners]) => {
        this.partners = partners;
        this.pendingPartners = pendingPartners;

        if (this.partners && this.pendingPartners) {
          this.currentPartnerCount = this.partners.length + this.pendingPartners.length;
        }
      });

    this.memberDetails$ = this.memberQuery.selectMemberDetails();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.unsubscribe();
  }

  openAddForm(): void {
    this.modalService.open(PartnerAddFormComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'partner-form',
      centered: true,
    });
  }

  openAcceptForm(): void {
    if (this.currentPartnerCount >= 1) {
      return;
    }
    this.modalService.open(PartnerAcceptFormComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'accept-form',
      centered: true,
    });
  }
}
