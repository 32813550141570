<div class="points-summary-bar d-flex justify-content-between d-lg-none">
  <!-- Back Button -->

  <a class="cursor-pointer d-flex align-items-center d-lg-none text-system-red" [routerLink]="[backButtonLink]">
    <span class="icon material-icons fw-bold pe-2">chevron_left</span>
    <span class="font-size-14 fw-bold">{{ 'reward.detail.back' | translate }}</span>
  </a>

  <div class="justify-content-center flex-grow-1 flex-shrink-1 h-100">
    <fgb-points-summary></fgb-points-summary>
  </div>
</div>
