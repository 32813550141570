<h5 class="text-start mt-5 mb-2">{{ 'score.predictor.rules.title' | translate }}</h5>
<div class="col-12 games-rules-container">
  <div class="row">
    <div ngbAccordion #a="ngbAccordion" [destroyOnHide]="false" class="w-100 mt-lg-0">
      <!--Q1-->
      <div ngbAccordionItem id="ngb-panel-0">
        <div ngbAccordionHeader>
          <div class="row align-items-center">
            <div class="col-md-1 col-2 d-flex">
              <div>
                <button aria-label="Toggle answer display" class="btn py-1" type="button" ngbAccordionButton>
                  <div>
                    <div class="icon icon-arrow-1-down text-info fw-bold d-flex align-items-center"></div>
                  </div>
                </button>
              </div>
            </div>
            <div class="col-10 faq-question-text fw-bold text-info ps-0" (click)="a.toggle('ngb-panel-0')">
              <div class="earn-question-titles">{{ 'score.predictor.rules.select.player.title' | translate }}</div>
            </div>
          </div>
        </div>
        <ng-template ngbAccordionCollapse class="border-muted">
          <div class="row py-0 ngbAccordionBody">
            <div class="content col-12">
              <div class="card card-body fantasy text-info">
                <div class="font-size-12">
                  {{ 'score.predictor.rules.select.player.text' | translate }}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <!--Q2-->
      <div ngbAccordionItem id="ngb-panel-1">
        <div ngbAccordionHeader>
          <div class="row align-items-center">
            <div class="col-md-1 col-2 d-flex">
              <div>
                <button aria-label="Toggle answer display" class="btn py-1" type="button" ngbAccordionButton>
                  <div>
                    <div class="icon icon-arrow-1-down text-info fw-bold d-flex align-items-center"></div>
                  </div>
                </button>
              </div>
            </div>
            <div class="col-10 faq-question-text fw-bold text-info ps-0" (click)="a.toggle('ngb-panel-1')">
              <div class="earn-question-titles">
                {{ 'score.predictor.rules.deadlines.title' | translate }}
              </div>
            </div>
          </div>
        </div>
        <ng-template ngbAccordionCollapse>
          <div class="row ngbAccordionBody">
            <div class="content col-12">
              <div class="card card-body fantasy text-info">
                <div class="font-size-12">
                  {{ 'score.predictor.rules.deadlines.text' | translate }}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <!--Q3-->
      <div ngbAccordionItem id="ngb-panel-2">
        <div ngbAccordionHeader>
          <div class="row align-items-center">
            <div class="col-md-1 col-2 d-flex">
              <div>
                <button aria-label="Toggle answer display" class="btn py-1" type="button" ngbAccordionButton>
                  <div>
                    <div class="icon icon-arrow-1-down text-info fw-bold d-flex align-items-center"></div>
                  </div>
                </button>
              </div>
            </div>
            <div class="col-10 faq-question-text fw-bold text-info ps-0" (click)="a.toggle('ngb-panel-2')">
              <div class="earn-question-titles">
                {{ 'score.predictor.rules.scoring.title' | translate }}
              </div>
            </div>
          </div>
        </div>
        <ng-template ngbAccordionCollapse>
          <div class="row ngbAccordionBody">
            <div class="content col-12">
              <div class="card card-body fantasy text-info">
                {{ 'score.predictor.rules.scoring.text' | translate }}
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <!--Q4-->
      <div ngbAccordionItem id="ngb-panel-3">
        <div ngbAccordionHeader>
          <div class="row align-items-center">
            <div class="col-md-1 col-2 d-flex">
              <div>
                <button aria-label="Toggle answer display" class="btn py-1" type="button" ngbAccordionButton>
                  <div>
                    <div class="icon icon-arrow-1-down text-info fw-bold d-flex align-items-center"></div>
                  </div>
                </button>
              </div>
            </div>
            <div class="col-10 faq-question-text fw-bold text-info ps-0" (click)="a.toggle('ngb-panel-3')">
              <div class="earn-question-titles">{{ 'score.predictor.rules.changing.player.title' | translate }}</div>
            </div>
          </div>
        </div>
        <ng-template ngbAccordionCollapse>
          <div class="row ngbAccordionBody">
            <div class="content col-12">
              <div class="card card-body fantasy text-info">
                <div class="font-size-12">
                  {{ 'score.predictor.rules.changing.player.text' | translate }}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
