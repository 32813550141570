import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fgb-tier-level-progress',
  templateUrl: './tier-level-progress.component.html',
  styleUrl: './tier-level-progress.component.scss'
})
export class TierLevelProgressComponent implements OnInit {
  @Input() Tier: any;
  @Input() tierId: number;
  @Input() isLast: boolean = false;
  constructor () {}

  ngOnInit(): void {}
}
