import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FeaturesRewardsItem, ScreenSizeService } from '@fgb/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit, OnChanges {
  @Input() filterTypesList: { label: string; value: string }[];
  @Output() filterTypesListEmitter: EventEmitter<{ label: string; value: string }[]> = new EventEmitter<
    {
      label: string;
      value: string;
    }[]
  >();
  @Input() selectedValues: string[];
  @Output() selectedValuesEmitter: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() showOffCanvas: boolean = false;
  @Output() showOffCanvasEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  showFilterOption: boolean = false;
  screenSize: number = 0;

  defaultSelectedValues: string[] = [];
  defaultFilterTypesList: { label: string; value: string }[] = [];

  filterTypesOptions = [
    { label: 'reward.filter_modal.all', value: FeaturesRewardsItem.ALL },
    { label: 'reward.filter_modal.marketplace', value: FeaturesRewardsItem.Marketplace },
    { label: 'reward.filter_modal.events', value: FeaturesRewardsItem.Events },
    { label: 'reward.filter_modal.lottos', value: FeaturesRewardsItem.Lottos },
  ];

  @ViewChild('myOffcanvas', { static: false }) myOffcanvas: NgbOffcanvas;

  get labelString(): string {
    return this.filterTypesList.length === 1
      ? this.filterTypesList.map((type: { label: string; value: string }) => type.label).join(', ')
      : `(${this.filterTypesList.length})`;
  }

  constructor(private screenSizeServices: ScreenSizeService, private elementRef: ElementRef) {}

  ngOnInit() {
    this.screenSizeServices.selectScreenWidth().subscribe((size: number) => (this.screenSize = size));
    this.defaultSelectedValues = [...this.selectedValues];
    this.defaultFilterTypesList = [...this.filterTypesList];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showOffCanvas) {
      if (this.showOffCanvas) {
        this.defaultSelectedValues = [...this.selectedValues];
        this.defaultFilterTypesList = [...this.filterTypesList];
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    const filterResult = document.querySelector('.filter-result');
    const optionList = document.querySelector('.filter-select-option');
    if (filterResult && optionList && !filterResult.contains(event.target) && !optionList.contains(event.target)) {
      this.toggleShowFilterOption();
    }
  }

  toggleShowFilterOption() {
    if (this.screenSize >= 600) {
      this.showFilterOption = !this.showFilterOption;
    } else {
      this.addStyleBackdrop();
    }
  }

  handleChangeSelectType(value: string) {
    if (value !== FeaturesRewardsItem.ALL) {
      const specificSelectedItem = this.defaultSelectedValues.find((val: string) => val === value) || '';
      const specificItem = this.filterTypesOptions.find(
        (item: { label: string; value: string | number }) => item.value === value
      );

      // item not in the list
      if (!specificSelectedItem && specificItem) {
        this.defaultSelectedValues.push(specificItem.value);
        this.defaultSelectedValues = this.defaultSelectedValues.filter((val: string) => val !== FeaturesRewardsItem.ALL);

        if (specificItem) {
          this.defaultFilterTypesList.push(specificItem);
          this.defaultFilterTypesList = this.defaultFilterTypesList.filter(
            (item: { label: string; value: string | number }) => item.value !== FeaturesRewardsItem.ALL
          );
        }
      } else {
        this.defaultSelectedValues = this.defaultSelectedValues.filter((val) => val !== specificSelectedItem);
        if (specificItem) {
          this.defaultFilterTypesList = this.defaultFilterTypesList.filter(
            (item: { label: string; value: string | number }) => item.value !== specificItem.value
          );
        }
      }
    }

    // uncheck all others options or check All option
    if (this.defaultFilterTypesList.length === 0 || value === FeaturesRewardsItem.ALL) {
      this.defaultSelectedValues = [FeaturesRewardsItem.ALL];
      this.defaultFilterTypesList = [{ label: 'reward.filter_modal.all', value: FeaturesRewardsItem.ALL }];
    }

    if (this.screenSize >= 600) {
      this.filterTypesListEmitter.emit(this.defaultFilterTypesList);
      this.selectedValuesEmitter.emit(this.defaultSelectedValues);
    }
  }

  handleCloseOffCanvas() {
    this.showOffCanvasEmitter.emit(false);
    this.defaultSelectedValues = [];
    this.defaultFilterTypesList = [];
  }

  handleApplyFilters() {
    this.filterTypesListEmitter.emit(this.defaultFilterTypesList);
    this.selectedValuesEmitter.emit(this.defaultSelectedValues);
    this.handleCloseOffCanvas();
  }

  addStyleBackdrop() {
    this.showOffCanvasEmitter.emit(true);
    this.elementRef.nativeElement.querySelector('.modal-backdrop').style = 'z-index:999;opacity:0.9;';
  }
}
