import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  Barcode,
  BarcodeQuery,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  SeatingDetailQuery,
  SeatingDetail,
  CardToken,
  CardTokenQuery,
} from '@fgb/core';
import { BarcodeService } from '@fgb/core';
import { Observable, Subscription } from 'rxjs';
import { MemberCard } from '@fgb/core';
import { config } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BalanceQuery } from '@fgb/core';
import { map } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
import { TierLevels } from 'src/app/shared/utilities/tier-levels';
@Component({
  selector: 'fgb-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit, OnDestroy {
  @Input() memberId: string;
  barcode$: Observable<Barcode>;
  populations = Populations;
  pointsBalance$: Observable<number | undefined>;
  combinedCard$: Observable<number | undefined>;
  member$: Observable<MemberDetails | undefined>;
  cardToken$: Observable<CardToken | undefined>;
  private subscription: Subscription;
  seatingDetail$: Observable<SeatingDetail | undefined>;
  memberCard$: Observable<MemberCard | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  tierName: string | undefined;
  tierImage: string | null;
  tierLevels = TierLevels;
  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private memberQuery: MemberQuery,
    private memberDetailsQuery: MemberQuery,
    private modalService: NgbModal,
    private seatingDetailQuery: SeatingDetailQuery,
    private cardTokenQuery: CardTokenQuery
  ) { }

  ngOnInit() {
    this.tierName = this.memberQuery.getUserContext().tierName
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.seatingDetail$ = this.seatingDetailQuery.selectCurrentSeatingDetails();
    this.barcode$ = this.barcodeQuery.select();
    this.member$ = this.memberDetailsQuery.selectMemberDetails();
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.subscription = this.barcodeService.fetchBarcode().subscribe();

    this.cardToken$ = this.cardTokenQuery.selectDefaultCard();

    this.pointsBalance$ = this.balanceQuery
      .selectPurse(config.purseConfig.virtualLoyalty)
      .pipe(map((bal) => Math.ceil(bal ?? 0)));
  }

  close() {
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
