import { Component, Input, OnInit } from '@angular/core';
import {
  MixedLoyaltyItemType,
  MixedLoyaltyQuery,
  MixedLoyaltyWalletItem,
  ProcessProviderId,
  ScorecardService,
  RewardsWalletService,
  RewardsWalletItem,
  RewardsWalletQuery,
  PopulationQuery
} from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  @Input() itemTypes: MixedLoyaltyItemType[] = [MixedLoyaltyItemType.All];
  @Input() walletItems: RewardsWalletItem[];
  unclaimedWalletItems$: Observable<MixedLoyaltyWalletItem[]>;
  claimedWalletItems$: Observable<MixedLoyaltyWalletItem[]>;
  isList: boolean = true;
  currentPopulation: string;
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;
  screenType = ScreenType;
  campaignOffersList = [];
  populations = Populations;
  constructor(
    private mixedLoyaltyQuery: MixedLoyaltyQuery,
    private scorecardService: ScorecardService,
    private rewardWalletItemsService: RewardsWalletService,
    private rewardWalletQuery: RewardsWalletQuery,
    private populationQuery: PopulationQuery
  ) {}

  async ngOnInit() {
    this.currentPopulation = this.populationQuery.getCurrentPopulation().DisplayName
    firstValueFrom(this.rewardWalletItemsService.fetchWalletItems());
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);

    await this.mixedLoyaltyQuery.walletFetch();

    /** select claimed marketplace and auction items */
    this.unclaimedWalletItems$ = this.mixedLoyaltyQuery.selectMixedLoyaltyWalletItemsNoFetch(
      false,
      this.marketplaceFilterTypes,
      this.itemTypes
    );
    /** select unclaimed marketplace and auction items */
    this.claimedWalletItems$ = this.mixedLoyaltyQuery.selectMixedLoyaltyWalletItemsNoFetch(
      true,
      this.marketplaceFilterTypes,
      this.itemTypes
    );

    firstValueFrom(this.scorecardService.fetchScorecards());
  }
  onCampaignOfferListChange(e: any) {
    this.campaignOffersList = e?.CampaignsAndEntitlements || [];
  }
}
