import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  config,
  CompanyQuery,
  Company,
  CompanyOfferModel,
  CompanyOfferQuery,
  CompanyService,
  AnnouncementService,
  CompanyOfferService,
  MemberQuery,
  MemberDetails,
  MemberCard,
  MemberCardQuery,
  PopulationQuery
} from '@fgb/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { Populations } from 'src/app/shared/utilities/populations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerAcceptFormComponent } from 'src/app/modules/user/components/partner-accept-form/partner-accept-form.component';
@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  populations = Populations;
  memberDetails$: Observable<MemberDetails | undefined>;
  memberCard$: Observable<MemberCard | undefined>;
  selectedMember: string;
  config = config;
  companies$: Observable<Company[]>;
  featuredOffers$: Observable<CompanyOfferModel[]>;
  name$: Observable<string>;
  screenType = ScreenType;
  campaignOffersList = [];
  currentPopulation: string;
  customOptions: OwlOptions = {
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      740: { items: 2 },
      900: { items: 3 },
    },
  };

  constructor(
    private companyService: CompanyService,
    private announcementService: AnnouncementService,
    private router: Router,
    private memberQuery: MemberQuery,
    private memberCardQuery: MemberCardQuery,
    private companyOfferQuery: CompanyOfferQuery,
    private companyQuery: CompanyQuery,
    private companyOfferService: CompanyOfferService,
    private modalService: NgbModal,
    private populationQuery: PopulationQuery
  ) {}

  ngOnInit() {
    this.currentPopulation = this.populationQuery.getCurrentPopulation().DisplayName
    this.initMemberAccounts();
    this.name$ = this.memberQuery.selectMemberDetails().pipe(
      map((m) => {
        if (m) {
          return `${m.FirstName}`;
        }
        return '';
      })
    );
    this.featuredOffers$ = this.companyOfferQuery.selectFeaturedOffers();
    firstValueFrom(this.companyService.fetchAllCompanies());

    this.companies$ = this.companyQuery.selectFavouriteCompanies().pipe(
      map((companies) => {
        return companies;
      })
    );
    firstValueFrom(this.announcementService.fetchAnnouncements());
    firstValueFrom(this.companyOfferService.fetchOffers(0, 0));
  }
  initMemberAccounts() {
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    // this.cards$ = this.cardQuery.selectCards();
    this.selectedMember = this.memberQuery.getUserContext().memberId;
  }
  navigateToOffer(offerId: number) {
    this.router.navigateByUrl(`offers/details/${offerId}`);
  }
  openAcceptForm(): void {
    this.modalService.open(PartnerAcceptFormComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'accept-form',
      centered: true,
    });
  }

  onCampaignOfferListChange(e: any) {
    this.campaignOffersList = e?.CampaignsAndEntitlements || [];
  }
}
