<!--Poimt Summary Bar-->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards'" [backButtonTitle]="'Rewards'"></fgb-points-summary-bar>
</div>
<!--Lotto Details-->
<div class="container">
  <div class="text-primary pt-3 cursor-pointer d-none d-lg-flex align-items-center" [routerLink]="'/rewards'">
    <span class="icon material-icons fw-bold text-system-red">chevron_left</span>
    <span class="font-size-14 fw-bold text-system-red ms-1">{{ 'reward.detail.back' | translate }}</span>
  </div>
</div>
<fgbcl-lotto-details [lottoId]="id" [showTermsAndConditions]="true"></fgbcl-lotto-details>
<!--Back Button-->
