import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@fgb/core';

@Component({
  selector: 'fgb-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.authService.checkApiStatus();

    if (this.authService.checkMaintenceMode()) {
      if (this.authService.hasCurrentRefreshToken()) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['login']);
      }
    }
  }

  ngOnInit() {}
}
