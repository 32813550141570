<div class="banner" role="banner">
  <h1 class="text-white">{{ 'tier.page.title' | translate }}</h1>
</div>

<div class="container">
  <div class="mt-4 font-size-14" [innerHTML]="'tier.page.description' | translate | markdownTranslate"></div>
  <div class="font-heading font-size-25 mt-4">
    {{'tier.progress.title' | translate}}
  </div>
  <div class="mt-3">
    <div *ngFor="let tier of tierLevels; let last = last">
      <fgb-tier-level-progress [Tier]="tier" [tierId]="tierId" [isLast]="last"/>
    </div>
  </div>
</div>